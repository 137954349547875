import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import React, { PropsWithChildren, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useCurrentUser from "../../hooks/useCurrentUser";
import useLocale from "../../hooks/useLocale";
import LanguagePicker from "../utils/LanguagePicker";

interface AuthLayoutProps {
  pageTitle: ReactNode;
  allowAuth?: boolean;
}

const AuthLayout = ({
  children,
  pageTitle,
  allowAuth,
}: PropsWithChildren<AuthLayoutProps>) => {
  const { user } = useCurrentUser();
  const { locale, localizePath } = useLocale();

  if (!locale) {
    return <Navigate to={localizePath("/login")} replace={true} />;
  }

  if (user && !allowAuth) {
    return <Navigate to={localizePath("/dashboard")} replace={true} />;
  }

  return (
    <Container component={"main"}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          component={Paper}
          sx={{
            maxWidth: {
              md: "800px",
              sm: "80%",
            },
          }}
        >
          <Grid item xs={12} md={7} padding={5}>
            <Typography
              component="h1"
              variant="h1"
              sx={{ mt: { xs: 5, md: 10 }, mb: 3 }}
            >
              {pageTitle}
            </Typography>

            {children}

            <Box textAlign={"right"} mt={5}>
              <LanguagePicker />
            </Box>
          </Grid>

          <Grid
            item
            md={5}
            display={{ sm: "none", md: "block" }}
            sx={{
              backgroundImage: "url(/images/login.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </Grid>
      </Box>
    </Container>
  );
};

export default AuthLayout;
