import { t } from "@lingui/macro";

export function translateCheckLog(typeName: String) {
  switch (typeName) {
    case "HttpCheckResult":
      return t`Http`;
    case "SslCheckResult":
      return t`SSL`;
  }

  return typeName;
}
