import { Trans } from "@lingui/macro";
import { Button, Container } from "@mui/material";
import PageHeader from "../../../styles/PageHeader";
import DashboardLayout from "../../layouts/DashboardLayout";
import {
  Project,
  useProjectDashboardByIdQuery,
} from "../../../hooks.generated";
import HostsStatus from "../../dashboard/HostsStatus";
import { useParams } from "react-router-dom";
import Edit from "@mui/icons-material/Edit";
import { useState } from "react";
import ProjectSaveDialog from "../../dialogs/ProjectSaveDialog";
import DashboardErrorPage from "./DashboardErrorPage";
import { getInfiniteStatusResultTimelineRange } from "../../../utils/getTimelineRange";

const ProjectPage = () => {
  const { projectId } = useParams();
  const [showEditDialog, editProject] = useState(false);
  const [statusResultTimeline] = useState(() => {
    return getInfiniteStatusResultTimelineRange();
  });

  const { data, loading } = useProjectDashboardByIdQuery({
    variables: {
      projectId: String(projectId),
      ...statusResultTimeline,
    },
    fetchPolicy: "network-only",
  });

  const project = data?.projectById.project;

  if (!loading && !project) {
    return <DashboardErrorPage message={<Trans>Project not found</Trans>} />;
  }

  return (
    <DashboardLayout
      loading={loading}
      pageHeader={
        <PageHeader
          title={project?.name}
          breadcrumbs={[
            {
              to: "/dashboard",
              name: <Trans>Dashboard</Trans>,
            },
            {
              to: "/dashboard/projects",
              name: <Trans>Projects</Trans>,
            },
          ]}
          actions={
            <Button
              variant="contained"
              startIcon={<Edit />}
              disableRipple
              disableElevation
              disableFocusRipple
              sx={{
                width: { xs: "100%", sm: "auto" },
                marginLeft: "auto",
                marginBottom: {
                  xs: 3,
                },
              }}
              onClick={() => editProject(true)}
            >
              <Trans>Edit project</Trans>
            </Button>
          }
        />
      }
    >
      <Container component={"main"}>
        <ProjectSaveDialog
          project={{
            projectId: project?.id,
            input: {
              name: project?.name || "",
            },
          }}
          open={showEditDialog}
          onClose={() => {
            editProject(false);
          }}
        />

        <HostsStatus
          projects={project ? ([project] as Project[]) : undefined}
          loading={loading}
        />
      </Container>
    </DashboardLayout>
  );
};

export default ProjectPage;
