import { Box, Container } from "@mui/system";
import React, { PropsWithChildren, ReactNode } from "react";
import useCurrentUser from "../../hooks/useCurrentUser";
import { Navigate, useParams } from "react-router-dom";
import useLocale from "../../hooks/useLocale";
import useCurrentProject from "../../hooks/useCurrentProject";
import DinoLoading from "../../images/loading-dino.jpg";
import MainMenu from "../dashboard/MainMenu";
import { Typography } from "@mui/material";
import { Trans } from "@lingui/macro";
import { css, Global } from "@emotion/react";

interface DashboardLayoutProps {
  pageHeader?: ReactNode;
  loading?: boolean;
}

const DashboardLayout = ({
  children,
  pageHeader,
  loading,
}: PropsWithChildren<DashboardLayoutProps>) => {
  const { user, loading: userLoading } = useCurrentUser();
  const { project, loading: projectLoading } = useCurrentProject();
  const { localizePath } = useLocale();
  const { projectId } = useParams();

  if (userLoading) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Box
          component="img"
          sx={{ maxWidth: "400px", marginTop: 10 }}
          src={DinoLoading}
        />
      </Box>
    );
  }

  if (!user) {
    return <Navigate to={localizePath("/login")} replace />;
  }

  if (projectId && !project && !projectLoading) {
    return <Navigate to={localizePath("/dashboard")} replace />;
  }

  return (
    <>
      <Global
        styles={css`
          html,
          body,
          body > div {
            height: 100%;
          }

          body > div {
            display: flex;
            flex-direction: column;
          }
        `}
      />

      <MainMenu project={project} />

      {loading && (
        <Box sx={{ textAlign: "center" }}>
          <Box
            component="img"
            sx={{ maxWidth: "400px", marginTop: 10 }}
            src={DinoLoading}
          />
        </Box>
      )}

      {!loading && (
        <>
          {pageHeader}
          <Box sx={{ paddingBottom: 1 }}>{children}</Box>
        </>
      )}

      <Container component={"footer"} sx={{ mt: "auto", pb: 2 }}>
        <Typography
          sx={{
            fontSize: 10,
            textTransform: "uppercase",
            color: (theme) => theme.palette.grey["500"],
          }}
        >
          <Trans>Version: {process.env.REACT_APP_VERSION || "unknown"}</Trans>
        </Typography>
      </Container>
    </>
  );
};

export default DashboardLayout;
