import React from "react";
import AppProviders from "./AppProviders";
import Router from "./Routes";
import { CssBaseline } from "@mui/material";
import { GlobalStyles } from "../styles/Global";

function App() {
  return (
    <AppProviders>
      <CssBaseline />
      <GlobalStyles />

      <Router />
    </AppProviders>
  );
}

export default App;
