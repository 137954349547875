import { Trans } from "@lingui/macro";
import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import {
  NotifyType,
  useAllIntegrationsForNotifyQuery,
} from "../../hooks.generated";

interface NotifySaveDialogProps {
  index: number | null;
  onClose(): void;
  watch: any;
  control: any;
  registerField: any;
}

const NotifySaveDialog = ({
  index,
  onClose,
  control,
  watch,
  registerField,
}: NotifySaveDialogProps) => {
  const { data } = useAllIntegrationsForNotifyQuery();

  if (index === null) {
    return null;
  }

  const selectedIntegration = data?.me.user?.integrations?.find(
    (integration) => integration?.id === watch(`notify.${index}.integrationId`)
  );

  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth
      onClose={() => {
        onClose();
      }}
      open={index !== null}
    >
      <DialogTitle>
        {index !== -1 ? <Trans>Edit notify</Trans> : <Trans>Add notify</Trans>}

        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Controller
          name={`notify.${index}.type`}
          control={control}
          render={(props: any) => (
            <FormControl sx={{ marginBottom: 5, marginTop: 3 }} fullWidth>
              <InputLabel id={`notify.${index}.type`}>
                <Trans>Type</Trans>
              </InputLabel>

              <Select
                fullWidth
                value={props.field.value}
                onChange={(e) => props.field.onChange(e.target.value)}
                labelId={`notify.${index}.type`}
              >
                <MenuItem value={NotifyType.Slack}>
                  <Trans>Slack</Trans>
                </MenuItem>
                <MenuItem value={NotifyType.Mail}>
                  <Trans>Mail</Trans>
                </MenuItem>
              </Select>
            </FormControl>
          )}
        />

        {watch(`notify.${index}.type`) === NotifyType.Slack && (
          <>
            <FormControl sx={{ marginBottom: 5 }} fullWidth>
              <InputLabel id={`notify.${index}.integrationId`}>
                <Trans>Integration</Trans>
              </InputLabel>

              <Controller
                name={`notify.${index}.integrationId`}
                control={control}
                render={(props: any) => (
                  <Select
                    fullWidth
                    value={props.field.value}
                    onChange={(e) => props.field.onChange(e.target.value)}
                    labelId={`notify.${index}.integrationId`}
                  >
                    {data?.me.user?.integrations
                      ?.filter(
                        (integration) =>
                          integration?.__typename === "SlackIntegration"
                      )
                      .map((integration) => (
                        <MenuItem value={integration?.id}>
                          {integration?.team}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl sx={{ marginBottom: 5 }} fullWidth>
              <InputLabel id={`notify.${index}.channelId`}>
                <Trans>Channel</Trans>
              </InputLabel>

              <Controller
                name={`notify.${index}.channelId`}
                control={control}
                render={(props: any) => (
                  <Select
                    fullWidth
                    disabled={!selectedIntegration}
                    value={props.field.value}
                    onChange={(e) => props.field.onChange(e.target.value)}
                    labelId={`notify.${index}.channelId`}
                  >
                    {selectedIntegration?.channels?.map((channel) => (
                      <MenuItem value={channel.id}>#{channel.name}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </>
        )}

        {watch(`notify.${index}.type`) === NotifyType.Mail && (
          <TextField
            margin="normal"
            required
            fullWidth
            type={"email"}
            autoComplete="email"
            label={<Trans>Email address</Trans>}
            {...registerField(`notify.${index}.email`)}
          />
        )}

        <Button variant="contained" sx={{ mt: 5 }} onClick={onClose}>
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default NotifySaveDialog;
