import { defineMessage } from "@lingui/macro";
import { I18n } from "@lingui/core";

export function formatTime(i18n: I18n, seconds: number): string {
  const days = Math.floor(seconds / (60 * 60 * 24));
  seconds -= days * 60 * 60 * 24;

  const hours = Math.floor(seconds / (60 * 60));
  seconds -= hours * (60 * 60);

  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  seconds = Math.floor(seconds);

  const message = [];

  if (days > 0) {
    message.push(
      i18n._(
        defineMessage({
          id: "format_time_days",
          message: "{days}d",
          values: {
            days,
          },
        })
      )
    );
  }

  if (hours > 0) {
    message.push(
      i18n._(
        defineMessage({
          id: "format_time_hours",
          message: "{hours}h",
          values: {
            hours,
          },
        })
      )
    );
  }

  if (minutes > 0) {
    message.push(
      i18n._(
        defineMessage({
          id: "format_time_minutes",
          message: "{minutes}m",
          values: {
            minutes,
          },
        })
      )
    );
  }

  if (seconds > 0) {
    message.push(
      i18n._(
        defineMessage({
          id: "format_time_seconds",
          message: "{seconds}s",
          values: {
            seconds,
          },
        })
      )
    );
  }

  return message.join(", ");
}
