import { Trans } from "@lingui/macro";
import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useState } from "react";
import useLocale from "../../hooks/useLocale";
import { TimelineRange } from "../../utils/getTimelineRange";
import HostForm from "../forms/HostForm";

interface HostSaveDialogProps {
  projectId: string;
  host?: any | null; // todo: can we remove the any?
  timelineRange: TimelineRange;
  open: boolean;
  onClose(): void;
}

const HostSaveDialog = ({
  projectId,
  host,
  timelineRange,
  open,
  onClose,
}: HostSaveDialogProps) => {
  const [loading, setLoading] = useState(false);
  const { navigate } = useLocale();

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      onClose={() => {
        if (loading) {
          return;
        }

        onClose();
      }}
      open={open}
    >
      <DialogTitle>
        {host?.id ? <Trans>Edit host</Trans> : <Trans>Create new host</Trans>}

        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <HostForm
          host={host}
          projectId={projectId}
          timelineRange={timelineRange}
          onSuccess={(hostSaveResponse) => {
            if (!host?.id) {
              navigate(
                `/dashboard/projects/${projectId}/hosts/${hostSaveResponse?.host?.id}`
              );
            }

            onClose();
          }}
          onLoad={(loading) => {
            setLoading(loading);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default HostSaveDialog;
