import { t } from "@lingui/macro";

export function translateInterval(interval: number) {
  if (interval < 60) {
    return t`every ${interval} seconds`;
  }

  if (interval < 60 * 60) {
    const minutes = interval / 60;

    if (minutes === 1) {
      return t`every minute`;
    }

    return t`every ${minutes} minutes`;
  }

  const hours = interval / 60 / 60;

  if (hours === 1) {
    return t`every hour`;
  }

  return t`every ${hours} hours`;
}
