import React, { ReactElement } from "react";
import PageHeader from "../../../styles/PageHeader";
import DashboardLayout from "../../layouts/DashboardLayout";
import DinoError from "../../../images/error-dino.png";
import { Box } from "@mui/system";

interface DashboardErrorPageProps {
  message: ReactElement;
}

const DashboardErrorPage = ({ message }: DashboardErrorPageProps) => {
  return (
    <DashboardLayout pageHeader={<PageHeader title={message} />}>
      <Box sx={{ textAlign: "center" }}>
        <Box
          component="img"
          sx={{ maxWidth: "400px", mt: 4 }}
          src={DinoError}
        />
      </Box>
    </DashboardLayout>
  );
};

export default DashboardErrorPage;
