import { Trans } from "@lingui/macro";
import { Box, Link as StyledLink } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import useLocale from "../../hooks/useLocale";
import LoginForm from "../forms/LoginForm";
import AuthLayout from "../layouts/AuthLayout";

const LoginPage = () => {
  const { navigate, localizePath } = useLocale();

  return (
    <AuthLayout pageTitle={<Trans>Login</Trans>}>
      <LoginForm
        onSuccess={() => {
          navigate("/dashboard");
        }}
      />

      <Box mt={1} mb={5}>
        <StyledLink
          fontSize={14}
          component={Link}
          to={localizePath("/forgot-password")}
        >
          <Trans>Forgot your password?</Trans>
        </StyledLink>
      </Box>

      <Box fontSize={14}>
        <Trans>No account?</Trans>{" "}
        <StyledLink component={Link} to={localizePath("/register")}>
          <Trans>Sign up for free!</Trans>
        </StyledLink>
      </Box>
    </AuthLayout>
  );
};

export default LoginPage;
