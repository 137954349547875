import { Trans } from "@lingui/macro";
import { Box, Link as StyledLink } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import useLocale from "../../hooks/useLocale";
import RegisterForm from "../forms/RegisterForm";
import AuthLayout from "../layouts/AuthLayout";

const RegisterPage = () => {
  const { navigate, localizePath } = useLocale();

  return (
    <AuthLayout pageTitle={<Trans>Register</Trans>}>
      <RegisterForm
        onSuccess={() => {
          navigate("/dashboard");
        }}
      />

      <Box fontSize={14} mt={1}>
        <Trans>Already have an account?</Trans>{" "}
        <StyledLink component={Link} to={localizePath("/login")}>
          <Trans>Click here to login!</Trans>
        </StyledLink>
      </Box>
    </AuthLayout>
  );
};

export default RegisterPage;
