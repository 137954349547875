import { Trans } from "@lingui/macro";
import { Box, Container, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import PageHeader from "../../../styles/PageHeader";
import AccountForm from "../../forms/AccountForm";
import DashboardLayout from "../../layouts/DashboardLayout";

const AccountPage = () => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <DashboardLayout
      pageHeader={
        <PageHeader
          title={<Trans>Account</Trans>}
          breadcrumbs={[
            {
              to: "/dashboard",
              name: <Trans>Dashboard</Trans>,
            },
          ]}
        />
      }
    >
      <Container component={"main"}>
        <Box component={Paper} padding={3}>
          <AccountForm
            onSuccess={() => {
              enqueueSnackbar(<Trans>Updated account</Trans>);
            }}
          />
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default AccountPage;
