export interface TimelineRange {
  timelineStart: number;
  timelineEnd: number;
}

export interface StatusResultTimelineRange {
  statusResultsTimelineStart: number;
  statusResultsTimelineEnd: number;
}

export function getTimelineRange(gap: number): TimelineRange {
  const now = new Date();

  return {
    timelineStart: now.getTime() / 1000 - gap,
    timelineEnd: now.getTime() / 1000,
  };
}

export function getInfiniteStatusResultTimelineRange(): StatusResultTimelineRange {
  const now = new Date();

  return {
    statusResultsTimelineStart: 0,
    statusResultsTimelineEnd: now.getTime() / 1000,
  };
}
