import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SlackIntegrationResult from "./integrations/SlackIntegrationResult";
import AccountPage from "./pages/dashboard/AccountPage";
import Home from "./pages/dashboard/Home";
import HostPage from "./pages/dashboard/HostPage";
import HostsPage from "./pages/dashboard/HostsPage";
import IntegrationsPage from "./pages/dashboard/IntegrationsPage";
import ProjectPage from "./pages/dashboard/ProjectPage";
import ProjectsPage from "./pages/dashboard/ProjectsPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import LoginPage from "./pages/LoginPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import RegisterPage from "./pages/RegisterPage";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:locale">
          <Route index element={<Navigate to={"login"} />} />

          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="reset-password/:token" element={<PasswordResetPage />} />

          <Route path="dashboard">
            <Route index element={<Home />} />

            <Route path="projects">
              <Route index element={<ProjectsPage />} />

              <Route path=":projectId" element={<ProjectPage />} />
              <Route path=":projectId/hosts" element={<HostsPage />} />
              <Route path=":projectId/hosts/:hostId" element={<HostPage />} />
            </Route>

            <Route path="integrations" element={<IntegrationsPage />} />
            <Route path="account" element={<AccountPage />} />
          </Route>

          <Route path="integrate/slack" element={<SlackIntegrationResult />} />

          <Route path="*" element={<Navigate to={"login"} />} />
        </Route>

        <Route path="*" element={<Navigate to={"/en/login"} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
