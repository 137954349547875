import { css, Global, useTheme } from "@emotion/react";
import React from "react";

export const GlobalStyles = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        body {
          background-color: ${theme.backgroundColor};
        }
      `}
    />
  );
};
