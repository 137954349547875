import React, { useState } from "react";
import { ErrorOption, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

import { Trans } from "@lingui/macro";
import {
  CurrentUserDocument,
  MeResponseStatus,
  SaveProjectMutation,
  SaveProjectMutationVariables,
  SaveProjectResponseStatus,
  useSaveProjectMutation,
} from "../../hooks.generated";
import { t } from "@lingui/macro";
import { useApolloClient } from "@apollo/client";

interface ProjectFormProps {
  project?: SaveProjectMutationVariables;
  onSuccess?(response: SaveProjectMutation["saveProject"]): void;
  onLoad?(loading: boolean): void;
}

interface SaveProjectData {
  name: string;
}

const saveProjectValidation = object({
  name: string().required(),
});

const ProjectForm = ({ onSuccess, onLoad, project }: ProjectFormProps) => {
  const client = useApolloClient();
  const [saveProject] = useSaveProjectMutation();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    setError,
    register: registerField,
    formState: { errors },
    reset,
  } = useForm<SaveProjectData>({
    resolver: yupResolver(saveProjectValidation),
    defaultValues: project?.input,
  });

  const saveProjectErrors: { [key: string]: ErrorOption } = {
    [SaveProjectResponseStatus.NotFound]: {
      message: t`Project not found`,
    },
    [SaveProjectResponseStatus.SomethingWentWrong]: {
      message: t`Something went wrong`,
    },
  };

  const onSubmit = async ({ name }: SaveProjectData) => {
    setLoading(true);

    if (onLoad) {
      onLoad(true);
    }

    try {
      const { data } = await saveProject({
        variables: {
          projectId: project?.projectId,
          input: {
            name,
          },
        },
      });

      if (data?.saveProject?.status === SaveProjectResponseStatus.Success) {
        if (!project?.projectId) {
          const currentUser = client.readQuery({
            query: CurrentUserDocument,
          });

          client.writeQuery({
            query: CurrentUserDocument,
            data: {
              me: {
                status: MeResponseStatus.Success,
                user: {
                  ...currentUser.me.user,
                  projects: [
                    ...currentUser.me.user.projects,
                    data?.saveProject?.project,
                  ],
                },
              },
            },
          });
        }

        onSuccess && onSuccess(data.saveProject);

        setLoading(false);

        reset();

        if (onLoad) {
          onLoad(false);
        }

        return;
      }

      setError("name", saveProjectErrors[String(data?.saveProject?.status)]);
    } catch (exception) {
      console.error(exception);

      setError("name", {
        message: t`Something went wrong`,
      });
    }

    setLoading(false);

    if (onLoad) {
      onLoad(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate id={"project-form"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            margin="normal"
            required
            fullWidth
            type={"text"}
            autoComplete="name"
            autoFocus
            disabled={loading}
            label={<Trans>Name</Trans>}
            helperText={errors.name?.message}
            error={!!errors.name?.message}
            {...registerField("name")}
          />
        </Grid>
      </Grid>

      <LoadingButton
        loading={loading}
        disabled={loading}
        type={"submit"}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mt: 5 }}
      >
        {project?.projectId ? <Trans>Save</Trans> : <Trans>Create</Trans>}
      </LoadingButton>
    </form>
  );
};

export default ProjectForm;
