import React from "react";
import { DotProps } from "recharts";
import { HostCheckType, Level } from "../../hooks.generated";
import { getLevelColor } from "../../utils/getLevelColor";
import { TimelineItem } from "../pages/dashboard/HostPage";

type TimelineDotProps = DotProps & {
  value?: number;
  payload?: TimelineItem;
  type: HostCheckType;
  opacity?: number;
};

const TimelineDot = ({ cx, cy, opacity, payload, type }: TimelineDotProps) => {
  if (!cx || !cy) {
    return null;
  }

  const level = (payload as any)[type.toLowerCase() + "Level"] || Level.Success;

  return (
    <circle
      fill={getLevelColor(level)}
      cx={cx}
      cy={cy}
      opacity={opacity}
      r={level === Level.Success ? 0 : 3}
    />
  );
};

export default TimelineDot;
