import { Trans } from "@lingui/macro";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from "@mui/material";
import React, { PropsWithChildren, ReactNode } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import useLocale from "../../hooks/useLocale";
import LanguagePicker from "../utils/LanguagePicker";

interface IntegrationLayoutProps {
  logo: ReactNode;
  title: ReactNode;
  subHeader: ReactNode;
}

const IntegrationLayout = ({
  logo,
  title,
  subHeader,
}: PropsWithChildren<IntegrationLayoutProps>) => {
  const { locale, localizePath } = useLocale();
  const [searchParams] = useSearchParams();
  const result = searchParams.get("result");

  const resultMessages = {
    success: (
      <Trans>The integration has been added. You can close this tab.</Trans>
    ),
    failed_to_load: (
      <Trans>
        Failed to load the integration reference. Retry again in a few minutes
      </Trans>
    ),
    not_found: (
      <Trans>
        The integration cannot be found. Retry again in a few minutes
      </Trans>
    ),
    already_configured: (
      <Trans>
        The integration is already configured. You can close this tab.
      </Trans>
    ),
    something_went_wrong: (
      <Trans>Something went wrong. Retry again in a few minutes</Trans>
    ),
    failed_to_load_slack: (
      <Trans>Failed to connect to Slack. Retry again in a few minutes.</Trans>
    ),
    failed_to_load_data: (
      <Trans>
        Failed to load Slack access token. Retry again in a few minutes.
      </Trans>
    ),
    invalid_token: (
      <Trans>Invalid Slack access token. Retry again in a few minutes.</Trans>
    ),
    failed_to_save: (
      <Trans>
        Failed to add the integration. Retry again in a few minutes.
      </Trans>
    ),
  };

  if (!locale) {
    return (
      <Navigate
        to={localizePath("/integrate/slack?result=" + result)}
        replace={true}
      />
    );
  }

  return (
    <Container component={"main"}>
      <Card
        sx={{
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 10,
        }}
      >
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: "#fff" }}>{logo}</Avatar>}
          title={title}
          subheader={subHeader}
        />

        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {resultMessages.hasOwnProperty(String(result))
              ? resultMessages[result as keyof typeof resultMessages]
              : resultMessages.something_went_wrong}
          </Typography>
        </CardContent>

        <CardActions disableSpacing>
          <Box ml="auto" mt={2} padding={1}>
            <LanguagePicker />
          </Box>
        </CardActions>
      </Card>
    </Container>
  );
};

export default IntegrationLayout;
