import React, { useState } from "react";
import {
  Controller,
  ErrorOption,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

import { Trans } from "@lingui/macro";
import {
  Header,
  HostByIdResponse,
  HostCheckType,
  HttpCheck,
  LocalProjectFragmentDoc,
  MailHostNotify,
  NotifyType,
  SaveHostCheckInput,
  SaveHostCheckNotifyInput,
  SaveHostMutation,
  SaveHostResponseStatus,
  SlackHostNotify,
  SslCheck,
  useSaveHostMutation,
} from "../../hooks.generated";
import { t } from "@lingui/macro";
import { translateInterval } from "../../utils/translateInterval";
import { useApolloClient } from "@apollo/client";
import { Add, Edit } from "@mui/icons-material";
import NotifyChip from "../dashboard/NotifyChip";
import NotifySaveDialog from "../dialogs/NotifySaveDialog";
import {
  getInfiniteStatusResultTimelineRange,
  TimelineRange,
} from "../../utils/getTimelineRange";
import HostCheckStatusCodes from "../dashboard/HostCheckStatusCodes";
import EditHostCheckStatusCodes from "../dashboard/EditHostCheckStatusCodes";
import HostCheckHeaders from "../dashboard/HostCheckHeaders";

interface HostFormProps {
  host?: HostByIdResponse["host"];
  projectId: string;
  timelineRange: TimelineRange;
  onSuccess?(response: SaveHostMutation["saveHost"]): void;
  onLoad?(loading: boolean): void;
}

interface NotifyData {
  type: NotifyType | null;
  integrationId?: string;
  channelId?: string;
  email?: string;
}

interface SaveHostData {
  name: string;
  hostname: string;
  port: number;

  checks: {
    http: {
      enabled: boolean;
      every: number;
      isHttps: boolean;
      path?: string;
      statusCodes: number[];
      headers: Header[];
    };
    ssl: {
      enabled: boolean;
      every: number;
      warningBeforeDays: number;
      errorBeforeDays: number;
    };
  };

  notify: NotifyData[];
}

const saveHostValidation = object({
  name: string().required(),
  hostname: string().required(),
  port: number().required().min(1),
});

const intervals = [
  5,
  30,
  60,
  60 * 2,
  60 * 5,
  60 * 10,
  60 * 30,
  60 * 60,
  60 * 60 * 2,
  60 * 60 * 4,
  60 * 60 * 8,
  60 * 60 * 12,
  60 * 60 * 24,
];

const HostForm = ({
  onSuccess,
  onLoad,
  host,
  projectId,
  timelineRange,
}: HostFormProps) => {
  const client = useApolloClient();
  const [saveHost] = useSaveHostMutation();
  const [loading, setLoading] = useState(false);
  const [notifyToEdit, setNotifyToEdit] = useState<number | null>(null);

  // todo: can we do this better...?
  const httpCheck = (host?.checks || []).find(
    (check) => (check as any).__typename === "HttpCheck"
  ) as HttpCheck;

  const sslCheck = (host?.checks || []).find(
    (check) => (check as any).__typename === "SslCheck"
  ) as SslCheck;

  const notify = (httpCheck ? httpCheck : sslCheck)?.notify
    ?.map((notify) => {
      // todo: can we remove the any?
      if ((notify as any)?.__typename === "SlackHostNotify") {
        return {
          type: NotifyType.Slack,
          integrationId: (notify as SlackHostNotify).integration?.id,
          channelId: (notify as SlackHostNotify).channelId,
          email: (notify as MailHostNotify).email,
        };
      }

      if ((notify as any)?.__typename === "MailHostNotify") {
        return {
          type: NotifyType.Mail,
          email: (notify as MailHostNotify).email,
        };
      }

      return null;
    })
    .filter(Boolean) as NotifyData[];

  const defaultValues = {
    ...host,
    notify,
    checks: {
      http: httpCheck
        ? {
            enabled: true,
            every: httpCheck.every,
            path: httpCheck.path || "/",
            isHttps: httpCheck.isHttps || true,
            statusCodes: httpCheck.statusCodes || [],
            headers: (httpCheck.headers || []).length
              ? httpCheck.headers || []
              : [
                  {
                    name: "",
                    value: "",
                  },
                ],
          }
        : {
            enabled: false,
            every: 60,
            path: "/",
            isHttps: true,
            statusCodes: [],
            headers: [
              {
                name: "",
                value: "",
              },
            ],
          },
      ssl: sslCheck
        ? {
            enabled: true,
            every: sslCheck.every,
            warningBeforeDays: sslCheck.warningBeforeDays || 14,
            errorBeforeDays: sslCheck.errorBeforeDays || 1,
          }
        : {
            enabled: false,
            every: 60,
            warningBeforeDays: 14,
            errorBeforeDays: 1,
          },
    },
  };

  const {
    handleSubmit,
    setError,
    register: registerField,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
  } = useForm<SaveHostData>({
    resolver: yupResolver(saveHostValidation),
    defaultValues,
  });

  const {
    fields: notifyFields,
    append: appendNotifyField,
    remove: removeNotifyFields,
  } = useFieldArray({
    control,
    name: "notify",
  });

  const saveHostErrors: { [key: string]: ErrorOption } = {
    [SaveHostResponseStatus.NotFound]: {
      message: t`Host not found`,
    },
    [SaveHostResponseStatus.SomethingWentWrong]: {
      message: t`Something went wrong`,
    },
    [SaveHostResponseStatus.ProjectNotFound]: {
      message: t`Project not found`,
    },
  };

  const onSubmit = async ({
    name,
    hostname,
    port,
    checks,
    notify: allNotify,
  }: SaveHostData) => {
    setLoading(true);

    if (onLoad) {
      onLoad(true);
    }

    try {
      const allChecks: SaveHostCheckInput[] = [];

      const notify: SaveHostCheckNotifyInput[] = allNotify
        .filter((notify) => !!notify.type)
        .map((notify) => {
          return {
            type: notify.type as NotifyType,
            integrationId: Number(notify.integrationId),
            channelId: notify.channelId,
            email: notify.email,
          };
        });

      if (checks.http.enabled) {
        allChecks.push({
          type: HostCheckType.Http,
          every: checks.http.every,
          isHttps: checks.http.isHttps,
          path: checks.http.path,
          notify,
          statusCodes: checks.http.statusCodes.length
            ? checks.http.statusCodes
            : undefined,
          headers: checks.http.headers
            .filter((header) => {
              return (
                (header?.name || "").length && (header?.value || "").length
              );
            })
            .map((header) => {
              return {
                name: header.name,
                value: header.value,
              };
            }),
        });
      }

      if (checks.ssl.enabled) {
        allChecks.push({
          type: HostCheckType.Ssl,
          every: checks.ssl.every,
          warningBeforeDays: Number(checks.ssl.warningBeforeDays),
          errorBeforeDays: Number(checks.ssl.errorBeforeDays),
          notify,
        });
      }

      const { data } = await saveHost({
        variables: {
          hostId: host?.id,
          projectId,
          input: {
            name,
            hostname,
            port,
            checks: allChecks,
          },
          ...timelineRange,
          ...getInfiniteStatusResultTimelineRange(),
        },
      });

      if (data?.saveHost?.status === SaveHostResponseStatus.Success) {
        if (!host?.id) {
          const project = client.readFragment({
            id: `Project:${projectId}`,
            fragment: LocalProjectFragmentDoc,
          });

          if (project) {
            client.writeFragment({
              id: `Project:${projectId}`,
              fragment: LocalProjectFragmentDoc,
              data: {
                ...project,
                hosts: [...project.hosts, data?.saveHost.host],
              },
            });
          }
        }

        onSuccess && onSuccess(data.saveHost);

        setLoading(false);

        reset();

        if (onLoad) {
          onLoad(false);
        }

        return;
      }

      setError(
        "name",
        saveHostErrors[
          data?.saveHost?.status || SaveHostResponseStatus.SomethingWentWrong
        ]
      );
    } catch (exception) {
      console.error(exception);

      setError("name", {
        message: t`Something went wrong`,
      });
    }

    setLoading(false);

    if (onLoad) {
      onLoad(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate id={"host-form"}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            margin="normal"
            required
            fullWidth
            type={"text"}
            autoComplete="name"
            autoFocus
            disabled={loading}
            label={<Trans>Name</Trans>}
            helperText={errors.name?.message}
            error={!!errors.name?.message}
            {...registerField("name")}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            type={"text"}
            autoComplete="hostname"
            disabled={loading}
            label={<Trans>Hostname</Trans>}
            helperText={errors.hostname?.message}
            error={!!errors.hostname?.message}
            {...registerField("hostname")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            type={"number"}
            autoComplete="port"
            disabled={loading}
            label={<Trans>Port</Trans>}
            helperText={errors.port?.message}
            error={!!errors.port?.message}
            {...registerField("port")}
          />
        </Grid>
      </Grid>

      <Typography mt={3} mb={3}>
        <Trans>Host checks</Trans>
      </Typography>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={6} sm={6}>
          <FormGroup>
            <FormControlLabel
              disabled={loading}
              control={
                <Controller
                  name="checks.http.enabled"
                  control={control}
                  render={(props: any) => (
                    <Switch
                      onChange={(e) => props.field.onChange(e.target.checked)}
                      checked={props.field.value}
                    />
                  )}
                />
              }
              label={<Trans>Http check</Trans>}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={6} sm={6}>
          {watch("checks.http.enabled") && (
            <Controller
              name={"checks.http.every"}
              control={control}
              render={(props: any) => (
                <Select
                  size={"small"}
                  fullWidth
                  value={props.field.value}
                  onChange={(e) => props.field.onChange(e.target.value)}
                >
                  {intervals.map((interval, index) => (
                    <MenuItem key={index} value={interval}>
                      {translateInterval(interval)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          )}
        </Grid>
      </Grid>

      {watch("checks.http.enabled") && (
        <Box component={Paper} padding={1} mb={5} variant={"outlined"}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormGroup>
                <FormControlLabel
                  disabled={loading}
                  control={
                    <Controller
                      name="checks.http.isHttps"
                      control={control}
                      render={(props: any) => (
                        <Switch
                          onChange={(e) =>
                            props.field.onChange(e.target.checked)
                          }
                          checked={props.field.value}
                        />
                      )}
                    />
                  }
                  label={<Trans>Is https</Trans>}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                type={"text"}
                autoComplete="path"
                disabled={loading}
                label={<Trans>Path</Trans>}
                helperText={errors.checks?.http?.path?.message}
                error={!!errors.checks?.http?.path?.message}
                {...registerField("checks.http.path")}
              />
            </Grid>

            <Grid item xs={12}>
              <Box mt={3} mb={1} sx={{ display: "flex" }}>
                <Typography>
                  <Trans>Valid HTTP status codes</Trans>
                </Typography>

                <EditHostCheckStatusCodes watch={watch} setValue={setValue}>
                  <Chip
                    size={"small"}
                    label={"Edit"}
                    color={"primary"}
                    icon={<Edit />}
                    sx={{
                      marginLeft: "auto",
                    }}
                  />
                </EditHostCheckStatusCodes>
              </Box>

              <HostCheckStatusCodes
                statusCodes={watch("checks.http.statusCodes")}
              />

              <Box mt={3} mb={1}>
                <Typography sx={{ mb: 3 }}>
                  <Trans>Headers</Trans>
                </Typography>

                <HostCheckHeaders
                  control={control}
                  registerField={registerField}
                  loading={loading}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <Grid container spacing={2} mb={2}>
        <Grid item xs={6} sm={6}>
          <FormGroup>
            <FormControlLabel
              disabled={loading}
              control={
                <Controller
                  name="checks.ssl.enabled"
                  control={control}
                  render={(props: any) => (
                    <Switch
                      onChange={(e) => props.field.onChange(e.target.checked)}
                      checked={props.field.value}
                    />
                  )}
                />
              }
              label={<Trans>SSL check</Trans>}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={6} sm={6}>
          {watch("checks.ssl.enabled") && (
            <Controller
              name={"checks.ssl.every"}
              control={control}
              render={(props: any) => (
                <Select
                  size={"small"}
                  fullWidth
                  value={props.field.value}
                  onChange={(e) => props.field.onChange(e.target.value)}
                >
                  {intervals.map((interval, index) => (
                    <MenuItem key={index} value={interval}>
                      {translateInterval(interval)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          )}
        </Grid>
      </Grid>

      {watch("checks.ssl.enabled") && (
        <Box component={Paper} padding={1} mb={5} variant={"outlined"}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                type={"number"}
                autoComplete="errorBeforeDays"
                disabled={loading}
                label={<Trans>Error before days</Trans>}
                helperText={errors.checks?.ssl?.errorBeforeDays?.message}
                error={!!errors.checks?.ssl?.errorBeforeDays?.message}
                {...registerField("checks.ssl.errorBeforeDays")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                type={"number"}
                autoComplete="warningBeforeDays"
                disabled={loading}
                label={<Trans>Warning before days</Trans>}
                helperText={errors.checks?.ssl?.warningBeforeDays?.message}
                error={!!errors.checks?.ssl?.warningBeforeDays?.message}
                {...registerField("checks.ssl.warningBeforeDays")}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {(watch("checks.ssl.enabled") || watch("checks.http.enabled")) && (
        <Box mb={2}>
          <Typography mt={3} mb={3}>
            <Trans>Notify</Trans>
          </Typography>

          {notifyFields.map((notifyField, notifyIndex) => (
            <NotifyChip
              key={notifyIndex}
              onRemove={removeNotifyFields}
              index={notifyIndex}
              watch={watch}
              onClick={() => {
                setNotifyToEdit(notifyIndex);
              }}
            />
          ))}

          <NotifySaveDialog
            watch={watch}
            control={control}
            index={notifyToEdit}
            registerField={registerField}
            onClose={() => {
              setNotifyToEdit(null);
            }}
          />

          <Chip
            sx={{
              marginBottom: 1,
              marginRight: 1,
            }}
            onClick={() => {
              appendNotifyField({
                type: null,
              });

              setNotifyToEdit(notifyFields.length);
            }}
            label={<Trans>Add notify</Trans>}
            icon={<Add />}
          />
        </Box>
      )}

      <LoadingButton
        loading={loading}
        disabled={loading}
        type={"submit"}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mt: 5 }}
      >
        {host?.id ? <Trans>Save</Trans> : <Trans>Create</Trans>}
      </LoadingButton>
    </form>
  );
};

export default HostForm;
