import { Trans } from "@lingui/macro";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  Container,
  Link as StyledLink,
} from "@mui/material";
import {
  DeleteProjectResponseStatus,
  useAllProjectsQuery,
  useDeleteProjectMutation,
} from "../../../hooks.generated";
import PageHeader from "../../../styles/PageHeader";
import DashboardLayout from "../../layouts/DashboardLayout";
import useLocale from "../../../hooks/useLocale";
import { Link } from "react-router-dom";
import DashboardErrorPage from "./DashboardErrorPage";
import ProjectSaveDialog from "../../dialogs/ProjectSaveDialog";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import DeleteWithConfirmation from "../../utils/DeleteWithConfirmation";
import { useSnackbar } from "notistack";
import { useApolloClient } from "@apollo/client";

const ProjectsPage = () => {
  const client = useApolloClient();
  const { localizePath } = useLocale();
  const { data, loading } = useAllProjectsQuery();
  const [createProjectOpen, createProject] = useState(false);
  const [deleteProject] = useDeleteProjectMutation();
  const { enqueueSnackbar } = useSnackbar();

  const projects = data?.me?.user?.projects || null;

  if (!loading && !projects) {
    return (
      <DashboardErrorPage message={<Trans>Failed to load projects</Trans>} />
    );
  }

  return (
    <DashboardLayout
      loading={loading}
      pageHeader={
        <PageHeader
          title={<Trans>Projects</Trans>}
          breadcrumbs={[
            {
              to: "/dashboard",
              name: <Trans>Dashboard</Trans>,
            },
          ]}
          actions={
            <Button
              variant="contained"
              startIcon={<Add />}
              disableRipple
              disableElevation
              disableFocusRipple
              sx={{
                width: { xs: "100%", sm: "auto" },
                marginLeft: "auto",
                marginBottom: {
                  xs: 3,
                },
              }}
              onClick={() => createProject(true)}
            >
              <Trans>Create project</Trans>
            </Button>
          }
        />
      }
    >
      <ProjectSaveDialog
        open={createProjectOpen}
        onClose={() => createProject(false)}
      />

      <Container component={"main"}>
        {(projects || []).length <= 0 && (
          <Alert severity="info">
            <Trans>No projects found.</Trans>
          </Alert>
        )}

        {projects?.map((project) => {
          if (!project) {
            return null;
          }

          return (
            <StyledLink
              component={Link}
              underline="none"
              to={localizePath(`/dashboard/projects/${project.id}/hosts`)}
              key={project.id}
            >
              <Card
                key={project.id}
                sx={{
                  marginBottom: 3,
                }}
              >
                <CardHeader
                  title={project.name}
                  subheader={
                    <Trans>Hosts: {project?.hosts?.length || 0}</Trans>
                  }
                  action={
                    <DeleteWithConfirmation
                      title={
                        <Trans>
                          Are you sure you want to delete this project?
                        </Trans>
                      }
                      onConfirm={async () => {
                        try {
                          const response = await deleteProject({
                            variables: {
                              projectId: project.id,
                            },
                          });

                          if (
                            response.data?.deleteProject.status ===
                            DeleteProjectResponseStatus.Success
                          ) {
                            client.cache.evict({
                              id: "Project:" + project.id,
                            });

                            enqueueSnackbar(<Trans>Deleted project</Trans>);

                            return;
                          }
                        } catch (exception) {
                          console.error(exception);
                        }

                        enqueueSnackbar(
                          <Trans>Failed to delete project</Trans>
                        );
                      }}
                    />
                  }
                />
              </Card>
            </StyledLink>
          );
        })}
      </Container>
    </DashboardLayout>
  );
};

export default ProjectsPage;
