import { t } from "@lingui/macro";
import { NotificationType } from "../hooks.generated";

export function translateNotificationType(type: NotificationType) {
  return {
    [NotificationType.InvalidHttpResponse]: t`Invalid http response`,
    [NotificationType.ValidHttpResponse]: t`Valid http response`,
    [NotificationType.SslAlmostExpired]: t`SSL almost expired`,
    [NotificationType.SslExpired]: t`SSL expired`,
    [NotificationType.SslFailure]: t`SSL failure`,
    [NotificationType.SslNotExpired]: t`SSL not expired`,
    [NotificationType.HttpResponseFailure]: t`Http response failure`,
    [NotificationType.Unknown]: t`Unknown`,
  }[type];
}
