import { t } from "@lingui/macro";
import { Chip } from "@mui/material";
import React from "react";
import {
  defaultStatusCodes,
  getColorForStatusCode,
  StatusCodeColor,
} from "../../utils/statusCode";

interface HostCheckStatusCodesProps {
  statusCodes: number[];
}

interface StatusCodeGroup {
  name: string;
  color: StatusCodeColor;
  statusCodes: number[];
}

const HostCheckStatusCodes = ({ statusCodes }: HostCheckStatusCodesProps) => {
  const statusCodesToRender = statusCodes.length
    ? statusCodes
    : defaultStatusCodes;

  const statusCodeGroups = statusCodesToRender
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => a - b)
    .reduce<StatusCodeGroup[]>((groups, statusCode) => {
      let existingGroupIndex = groups.findIndex((group) => {
        const groupHundredRange = parseInt(String(group.statusCodes[0] / 100));
        const hundredRange = parseInt(String(statusCode / 100));

        if (groupHundredRange <= 5) {
          if (groupHundredRange !== hundredRange) {
            return false;
          }
        }

        return (
          group.statusCodes[group.statusCodes.length - 1] === statusCode - 1
        );
      });

      if (existingGroupIndex === -1) {
        groups.push({
          name: String(statusCode),
          color: getColorForStatusCode(statusCode),
          statusCodes: [statusCode],
        });
      } else {
        groups[existingGroupIndex].statusCodes.push(statusCode);

        const minStatusCode = groups[existingGroupIndex].statusCodes[0];
        const maxStatusCode =
          groups[existingGroupIndex].statusCodes[
            groups[existingGroupIndex].statusCodes.length - 1
          ];

        groups[
          existingGroupIndex
        ].name = t`${minStatusCode} to ${maxStatusCode}`;
      }
      return groups;
    }, []);

  return (
    <>
      {statusCodeGroups.map((group, index) => (
        <Chip
          key={index}
          sx={{
            marginBottom: 1,
            marginRight: 1,
          }}
          size="small"
          color={group.color}
          label={group.name}
        />
      ))}
    </>
  );
};

export default HostCheckStatusCodes;
