export function getCookieDomain() {
  const hostnames = window.location.hostname.split(".");

  if (!hostnames.length) {
    return null;
  }

  if (hostnames.length === 1) {
    return hostnames[0];
  }

  const reverseHostname = hostnames.reverse();

  return reverseHostname[1] + "." + reverseHostname[0];
}

export function setCookie(name: string, value?: string, expires?: Date) {
  if (!value) {
    deleteCookie(name);

    return;
  }

  const domain = getCookieDomain();
  const cookieString = `${name}=${encodeURIComponent(value)};${
    expires ? ` expires=${expires.toUTCString()};` : ""
  }${
    domain ? ` domain=${getCookieDomain()};` : ""
  }SameSite=Lax; path=/; Secure;`;

  document.cookie = cookieString;
}

export function deleteCookie(name: string) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${getCookieDomain()}; path=/;`;
}

export function getCookie(name: string): string | null {
  const cookieName = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieParts = decodedCookie.split(";");

  for (
    let cookePartIndex = 0;
    cookePartIndex < cookieParts.length;
    cookePartIndex++
  ) {
    let cookiePart = cookieParts[cookePartIndex];

    while (cookiePart.charAt(0) === " ") {
      cookiePart = cookiePart.substring(1);
    }

    if (cookiePart.indexOf(cookieName) === 0) {
      return decodeURIComponent(
        cookiePart.substring(cookieName.length, cookiePart.length)
      );
    }
  }

  return null;
}
