import { Trans } from "@lingui/macro";
import { Box, Link as StyledLink, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useLocale from "../../hooks/useLocale";
import ForgotPasswordForm from "../forms/ForgotPasswordForm";
import AuthLayout from "../layouts/AuthLayout";

const ForgotPasswordPage = () => {
  const { localizePath } = useLocale();
  const [success, setSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <AuthLayout pageTitle={<Trans>Password reminder</Trans>}>
      {success ? (
        <Typography sx={{ mb: 3 }}>
          <Trans>We have send you a password reset link.</Trans>
        </Typography>
      ) : (
        <>
          <Typography sx={{ mb: 3 }}>
            <Trans>We will send you an email with a password reset link.</Trans>
          </Typography>

          <ForgotPasswordForm
            onSuccess={() => {
              setSuccess(true);
              enqueueSnackbar(<Trans>Password reset link sent</Trans>);
            }}
          />
        </>
      )}

      <Box mt={1} mb={5}>
        <StyledLink fontSize={14} component={Link} to={localizePath("/login")}>
          <Trans>Did you remember your password?</Trans>
        </StyledLink>
      </Box>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
