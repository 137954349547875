import { t, Trans } from "@lingui/macro";
import {
  Alert,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import PageHeader from "../../../styles/PageHeader";
import DashboardLayout from "../../layouts/DashboardLayout";
import {
  HostCheckNotification,
  HostCheckResult,
  HostCheckType,
  Level,
  NotificationType,
  PaginationInput,
  HttpCheckResult,
  SslCheckResult,
  useHostByIdQuery,
  useProjectByIdQuery,
} from "../../../hooks.generated";
import { useParams } from "react-router-dom";
import HostSaveDialog from "../../dialogs/HostSaveDialog";
import { useEffect, useState } from "react";
import Edit from "@mui/icons-material/Edit";
import DashboardErrorPage from "./DashboardErrorPage";
import { Box } from "@mui/system";
import { formatTime } from "../../../utils/formatTime";
import { translateNotificationType } from "../../../translations/notificationTypes";
import { useLingui } from "@lingui/react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { getLevelColor } from "../../../utils/getLevelColor";
import {
  getInfiniteStatusResultTimelineRange,
  getTimelineRange,
} from "../../../utils/getTimelineRange";
import LevelIcon from "../../utils/LevelIcon";
import { translateCheckLog } from "../../../translations/checkLog";
import HostTimelineRangeSelection from "../../dashboard/HostTimelineRangeSelection";
import TimelineDot from "../../dashboard/TimelineDot";
import TimelineTooltip from "../../dashboard/TimelineTooltip";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import PaperLoading from "../../utils/PaperLoading";
import HostStateChange from "../../dashboard/HostStateChange";

interface LatestStatus {
  level: Level;
  expiresIn?: number;
}

export interface TimelineItem {
  date: number;

  sslTook: number;
  sslLevel: Level;

  httpTook: number;
  httpLevel: Level;
}

interface TimelineLine {
  name: string;
  color: string;
  type: HostCheckType;
}

interface TimelineOpacity {
  sslTook: number;
  httpTook: number;
}

const HostPage = () => {
  const { i18n } = useLingui();

  const { hostId, projectId } = useParams();
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [showEditDialog, editHost] = useState(false);
  const [notificationsPagination, setNotificationPagination] =
    useState<PaginationInput>({
      page: 1,
      limit: 15,
    });

  const [resultsPagination, setResultsPagination] = useState<PaginationInput>({
    page: 1,
    limit: 15,
  });

  const [customTimelineRange, setCustomTimelineRange] = useState(false);
  const [timelineRange, setTimelineRange] = useState(() => {
    return getTimelineRange(24 * 60 * 60);
  });
  const [statusResultTimelineRange, setStatusResultTimelineRange] = useState(
    () => {
      return getInfiniteStatusResultTimelineRange();
    }
  );

  const [timelineOpacity, setTimelineOpacity] = useState<TimelineOpacity>({
    sslTook: 1,
    httpTook: 1,
  });

  const [loadingStates, setLoadingStates] = useState({
    timeline: false,
    checkResults: false,
    notifications: false,
  });

  const {
    data: hostData,
    previousData: previousHostData,
    loading: hostLoading,
    refetch: hostRefetch,
  } = useHostByIdQuery({
    variables: {
      hostId: String(hostId),
      ...timelineRange,
      ...statusResultTimelineRange,
      notificationsPagination,
      resultsPagination,
    },
    fetchPolicy: "network-only",
  });

  const { data: projectData, loading: projectLoading } = useProjectByIdQuery({
    variables: {
      projectId: String(projectId),
    },
    fetchPolicy: "network-only",
  });

  const project = projectData?.projectById.project;
  const host = hostData?.hostById.host || previousHostData?.hostById?.host;

  useEffect(() => {
    if (host && !initialLoaded) {
      setInitialLoaded(true);
    }
  }, [host, initialLoaded]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (hostLoading) {
        return;
      }

      if (!customTimelineRange) {
        setTimelineRange(getTimelineRange(24 * 60 * 60));
      }
      setStatusResultTimelineRange(getInfiniteStatusResultTimelineRange());
    }, 30 * 1000);
    return () => clearInterval(interval);
  }, [customTimelineRange, hostLoading]);

  useEffect(() => {
    if (!hostLoading) {
      setLoadingStates({
        checkResults: false,
        notifications: false,
        timeline: false,
      });
    }
  }, [hostLoading]);

  if (!projectLoading && !project) {
    return <DashboardErrorPage message={<Trans>Project not found</Trans>} />;
  }

  if (!hostLoading && !host) {
    return <DashboardErrorPage message={<Trans>Host not found</Trans>} />;
  }

  const notifications: HostCheckNotification[] = (host?.checks || [])
    .reduce<HostCheckNotification[]>((notifications, check) => {
      return [
        ...notifications,
        ...(check?.stats?.notifications?.items || []),
      ] as HostCheckNotification[];
    }, [])
    .sort((a, b) => Number(b.id) - Number(a.id));

  const checkResults: HostCheckResult[] = (host?.checks || [])
    .reduce<HostCheckResult[]>((checkResults, check) => {
      return [
        ...checkResults,
        ...(check?.stats?.results?.items || []),
      ] as HostCheckResult[];
    }, [])
    .sort((a, b) => Number(b.id) - Number(a.id));

  const latestStatus = (host?.checks || []).reduce<LatestStatus>(
    (status, check) => {
      const results = check?.stats?.statusResults?.items || [];
      const lastResult = results.length ? results[0] : null;

      if (!lastResult) {
        return status;
      }

      if (lastResult.__typename === "SslCheckResult" && lastResult.expiresAt) {
        const expiresAt = new Date(lastResult.expiresAt * 1000);
        const now = new Date();

        const diff = Math.abs(expiresAt.getTime() - now.getTime());
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

        status.expiresIn = diffDays;
      }

      if (lastResult.level === Level.Error) {
        status.level = Level.Error;
        return status;
      }

      if (
        status.level === Level.Success &&
        lastResult.level === Level.Warning
      ) {
        status.level = Level.Warning;

        return status;
      }

      return status;
    },
    {
      level: Level.Success,
    }
  );

  const timeline = (host?.checks || []).reduce<TimelineItem[]>(
    (timeline, check) => {
      for (const checkTimelineItem of check?.stats?.timeline || []) {
        let timelineItemIndex = timeline.findIndex(
          (timelineItemToFind) =>
            timelineItemToFind.date === checkTimelineItem.date
        );

        if (timelineItemIndex === -1) {
          timeline.push({
            date: checkTimelineItem.date,
            sslTook: 0,
            sslLevel: Level.Success,
            httpTook: 0,
            httpLevel: Level.Success,
          });

          timelineItemIndex = timeline.length - 1;
        }

        switch (check?.__typename) {
          case "SslCheck":
            timeline[timelineItemIndex].sslTook = checkTimelineItem.took;
            timeline[timelineItemIndex].sslLevel = checkTimelineItem.level;
            break;
          case "HttpCheck":
            timeline[timelineItemIndex].httpTook = checkTimelineItem.took;
            timeline[timelineItemIndex].httpLevel = checkTimelineItem.level;
            break;
        }
      }

      return timeline;
    },
    []
  );

  const lines = [
    host?.checks?.find((check) => check?.__typename === "SslCheck") && {
      name: t`SSL check`,
      type: HostCheckType.Ssl,
      color: "#28a3fb",
    },
    host?.checks?.find((check) => check?.__typename === "HttpCheck") && {
      name: t`Http check`,
      type: HostCheckType.Http,
      color: "#789bff",
    },
  ].filter(Boolean) as TimelineLine[];

  const getCheckLogMessage = (checkResult: HostCheckResult) => {
    switch (checkResult.type) {
      case NotificationType.InvalidHttpResponse:
        const statusCode = (checkResult as HttpCheckResult).statusCode;

        return t`Invalid status code: ${statusCode}`;
      case NotificationType.SslAlmostExpired:
        const expiresAt = (checkResult as SslCheckResult).expiresAt;
        return t`SSL will expire at ${i18n.date(new Date(expiresAt * 1000), {
          dateStyle: "short",
        })}`;
      case NotificationType.SslExpired:
        return t`SSL is expired`;
    }

    return checkResult.message;
  };

  const notificationsPaginationPage = notificationsPagination.page || 1;
  const notificationsPaginationLimit = notificationsPagination.limit || 15;

  const resultsPaginationPage = resultsPagination.page || 1;
  const resultsPaginationLimit = resultsPagination.limit || 15;

  return (
    <DashboardLayout
      loading={projectLoading || (hostLoading && !initialLoaded)}
      pageHeader={
        <PageHeader
          title={host?.name}
          breadcrumbs={[
            {
              to: "/dashboard",
              name: <Trans>Dashboard</Trans>,
            },
            {
              to: "/dashboard/projects",
              name: <Trans>Projects</Trans>,
            },
            {
              to: `/dashboard/projects/${project?.id}`,
              name: project?.name,
            },
            {
              to: `/dashboard/projects/${project?.id}/hosts`,
              name: <Trans>Hosts</Trans>,
            },
          ]}
          actions={
            <Box
              sx={{
                display: "flex",
                width: {
                  xs: "100%",
                },
                alignItems: "center",
                marginBottom: {
                  sm: 1,
                },
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
              }}
            >
              <Box
                sx={{
                  marginLeft: {
                    md: "auto",
                  },
                  marginRight: {
                    sm: "auto",
                    md: 1,
                  },
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                <HostTimelineRangeSelection
                  timelineRange={timelineRange}
                  disabled={hostLoading}
                  onTimelineUpdate={(updatedTimelineRange) => {
                    setCustomTimelineRange(true);
                    setTimelineRange(updatedTimelineRange);
                    setNotificationPagination({
                      page: 1,
                      limit: notificationsPaginationLimit,
                    });
                    setResultsPagination({
                      page: 1,
                      limit: resultsPaginationLimit,
                    });
                    setLoadingStates({
                      ...loadingStates,
                      checkResults: true,
                      notifications: true,
                      timeline: true,
                    });
                  }}
                />
              </Box>

              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                  display: {
                    xs: "flex",
                  },
                  alignItems: {
                    xs: "center",
                  },
                  marginTop: {
                    xs: 1,
                  },
                  marginBottom: {
                    xs: 1,
                  },
                }}
              >
                <HostStateChange host={host} />

                <Button
                  variant="contained"
                  startIcon={<Edit />}
                  disableRipple
                  disableElevation
                  disableFocusRipple
                  sx={{
                    marginLeft: 3,
                    width: { xs: "100%", sm: "auto" },
                  }}
                  onClick={() => editHost(true)}
                >
                  <Trans>Edit host</Trans>
                </Button>
              </Box>
            </Box>
          }
        />
      }
    >
      <Container component={"main"}>
        <Grid container sx={{ mb: 5 }} spacing={5}>
          <Grid item xs={12} sm={12} lg={8}>
            <Paper
              sx={{
                p: 3,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <PaperLoading loading={loadingStates.timeline} />

              <ResponsiveContainer width={"100%"} height={200}>
                <LineChart
                  data={timeline}
                  margin={{ top: 8, right: 8, bottom: 0, left: 8 }}
                >
                  <CartesianGrid strokeDasharray="1 1" opacity={0.6} />

                  {lines.map((line, lineIndex) => {
                    const dataKey = line.type.toLowerCase() + "Took";
                    const opacity =
                      dataKey in timelineOpacity
                        ? (timelineOpacity as any)[dataKey]
                        : 1;

                    return (
                      <Line
                        type={"monotone"}
                        key={lineIndex}
                        dataKey={dataKey}
                        stroke={line.color}
                        name={line.name}
                        fill={line.color}
                        strokeWidth={2}
                        strokeOpacity={opacity}
                        activeDot={{ r: 6 }}
                        isAnimationActive={false}
                        dot={<TimelineDot type={line.type} opacity={opacity} />}
                      />
                    );
                  })}

                  <Legend
                    verticalAlign="bottom"
                    onMouseEnter={(event: any) => {
                      setTimelineOpacity(
                        Object.keys(timelineOpacity).reduce<TimelineOpacity>(
                          (opacity, dataKey) => {
                            if (dataKey === event.dataKey) {
                              (opacity as any)[dataKey] = 1;
                            } else {
                              (opacity as any)[dataKey] = 0.2;
                            }

                            return opacity;
                          },
                          {} as TimelineOpacity
                        )
                      );
                    }}
                    onMouseLeave={(event: any) => {
                      setTimelineOpacity(
                        Object.keys(timelineOpacity).reduce<TimelineOpacity>(
                          (opacity, dataKey) => {
                            (opacity as any)[dataKey] = 1;

                            return opacity;
                          },
                          {} as TimelineOpacity
                        )
                      );
                    }}
                    wrapperStyle={{
                      display: "flex",
                      alignItems: "center",
                      height: "auto",
                      paddingTop: "5px",
                    }}
                  />

                  <Tooltip
                    wrapperStyle={{ outline: "none" }}
                    isAnimationActive={false}
                    content={<TimelineTooltip />}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} lg={4}>
            <Paper
              sx={{
                p: 3,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid " + getLevelColor(latestStatus.level),
              }}
            >
              <Typography
                sx={{
                  color: getLevelColor(latestStatus.level),
                  textAlign: "center",
                  fontSize: 30,
                }}
              >
                {latestStatus.level === Level.Success && <Trans>ONLINE</Trans>}
                {latestStatus.level === Level.Error && <Trans>OFFLINE</Trans>}
                {latestStatus.level === Level.Warning && <Trans>WARNING</Trans>}
              </Typography>

              {latestStatus.expiresIn !== undefined &&
                latestStatus.level !== Level.Error && (
                  <Typography sx={{ fontSize: 14, textAlign: "center" }}>
                    {latestStatus.expiresIn <= 0 && (
                      <Trans>Certificate is expired</Trans>
                    )}
                    {latestStatus.expiresIn > 0 && (
                      <Trans>
                        Certificate will expire in {latestStatus.expiresIn} days
                      </Trans>
                    )}
                  </Typography>
                )}
            </Paper>
          </Grid>
        </Grid>

        <Box
          component={Paper}
          sx={{ width: "100%", mb: 3, position: "relative" }}
        >
          <PaperLoading loading={loadingStates.checkResults} />

          <Toolbar
            sx={{
              flex: "1 1 100%",
              padding: 3,
              paddingLeft: 4,
              paddingRight: 4,
            }}
          >
            <Typography variant="h6" component="div">
              <Trans>Check results</Trans>
            </Typography>
          </Toolbar>

          <TableContainer>
            <Table size="small">
              <TableBody>
                {checkResults.map((checkResult) => (
                  <TableRow key={checkResult.id}>
                    <TableCell component="th" scope="row" style={{ width: 40 }}>
                      <LevelIcon level={checkResult.level} />
                    </TableCell>

                    <TableCell align="left" width={"200px"}>
                      <Typography fontSize={14}>
                        {translateNotificationType(checkResult.type)}
                      </Typography>

                      <Typography fontSize={12}>
                        {translateCheckLog((checkResult as any).__typename)}
                      </Typography>
                    </TableCell>

                    <TableCell align="left">
                      {getCheckLogMessage(checkResult)}
                    </TableCell>

                    <TableCell align="right" width={"200px"}>
                      <Typography>
                        {i18n.date(new Date(checkResult.date * 1000), {
                          timeStyle: "medium",
                          dateStyle: "short",
                        })}
                      </Typography>

                      <Typography fontSize={12}>
                        <Trans>Took: {checkResult.took || 0}ms</Trans>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}

                {checkResults.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Alert severity="info" sx={{ mb: 5 }}>
                        <Trans>No check results in the selected period.</Trans>
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        sx={{ marginLeft: "auto" }}
                        disabled={resultsPaginationPage <= 1 || hostLoading}
                        onClick={() => {
                          setResultsPagination({
                            page: resultsPaginationPage - 1,
                            limit: resultsPaginationLimit,
                          });
                          setLoadingStates({
                            ...loadingStates,
                            checkResults: true,
                          });
                          hostRefetch();
                        }}
                      >
                        <ChevronLeft />
                      </IconButton>

                      <IconButton
                        sx={{ marginLeft: 1 }}
                        disabled={checkResults.length <= 0 || hostLoading}
                        onClick={() => {
                          setResultsPagination({
                            page: resultsPaginationPage + 1,
                            limit: resultsPaginationLimit,
                          });
                          setLoadingStates({
                            ...loadingStates,
                            checkResults: true,
                          });
                          hostRefetch();
                        }}
                      >
                        <ChevronRight />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>

        <Box component={Paper} sx={{ width: "100%", position: "relative" }}>
          <PaperLoading loading={loadingStates.notifications} />

          <Toolbar
            sx={{
              flex: "1 1 100%",
              padding: 3,
              paddingLeft: 4,
              paddingRight: 4,
            }}
          >
            <Typography variant="h6" component="div">
              <Trans>Sent notifications</Trans>
            </Typography>
          </Toolbar>

          <TableContainer>
            <Table size="small">
              <TableBody>
                {notifications.map((notification) => (
                  <TableRow key={notification.id}>
                    <TableCell component="th" scope="row" style={{ width: 40 }}>
                      <LevelIcon level={notification.level} />
                    </TableCell>

                    <TableCell align="left">
                      {translateNotificationType(notification.type)}
                    </TableCell>

                    <TableCell align="right" width={"200px"}>
                      <Typography>
                        {i18n.date(new Date(notification.date * 1000), {
                          timeStyle: "medium",
                          dateStyle: "short",
                        })}
                      </Typography>

                      {notification.downtime > 0 && (
                        <Typography fontSize={12}>
                          <Trans>
                            Downtime: {formatTime(i18n, notification.downtime)}
                          </Trans>
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                ))}

                {notifications.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Alert severity="info" sx={{ mb: 5 }}>
                        <Trans>
                          No notifications sent in the selected period.
                        </Trans>
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        sx={{ marginLeft: "auto" }}
                        disabled={
                          notificationsPaginationPage <= 1 || hostLoading
                        }
                        onClick={() => {
                          setNotificationPagination({
                            page: notificationsPaginationPage - 1,
                            limit: notificationsPaginationLimit,
                          });
                          setLoadingStates({
                            ...loadingStates,
                            notifications: true,
                          });
                          hostRefetch();
                        }}
                      >
                        <ChevronLeft />
                      </IconButton>

                      <IconButton
                        sx={{ marginLeft: 1 }}
                        disabled={notifications.length <= 0 || hostLoading}
                        onClick={() => {
                          setNotificationPagination({
                            page: notificationsPaginationPage + 1,
                            limit: notificationsPaginationLimit,
                          });
                          setLoadingStates({
                            ...loadingStates,
                            notifications: true,
                          });
                          hostRefetch();
                        }}
                      >
                        <ChevronRight />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Container>

      {host && (
        <HostSaveDialog
          projectId={String(projectId)}
          host={host ? host : null}
          timelineRange={timelineRange}
          open={showEditDialog}
          onClose={() => {
            editHost(false);
          }}
        />
      )}
    </DashboardLayout>
  );
};

export default HostPage;
