import React, { useState } from "react";
import { ErrorOption, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

import { Trans } from "@lingui/macro";
import {
  CurrentUserDocument,
  MeResponseStatus,
  UpdateUserResponse,
  UpdateUserResponseStatus,
  useSaveUserMutation,
} from "../../hooks.generated";
import { t } from "@lingui/macro";
import { useApolloClient } from "@apollo/client";
import useCurrentUser from "../../hooks/useCurrentUser";

interface AccountFormProps {
  onSuccess?(response: UpdateUserResponse): void;
}

interface SaveUserData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  currentPassword: string;
}

const saveUserValidation = object().shape(
  {
    firstName: string().required(),
    lastName: string().required(),
    email: string().required().email(),

    password: string().when("currentPassword", {
      is: (currentPassword: string | any[], password: string | any[]) =>
        currentPassword?.length || password?.length,
      then: (rule) => rule.min(5).required(),
      otherwise: (rule) => rule.nullable(),
    }),

    currentPassword: string().when("password", {
      is: (currentPassword: string | any[], password: string | any[]) =>
        currentPassword?.length || password?.length,
      then: (rule) => rule.min(5).required(),
      otherwise: (rule) => rule.nullable(),
    }),
  },
  [
    ["password", "currentPassword"],
    ["currentPassword", "password"],
  ]
);

const AccountForm = ({ onSuccess }: AccountFormProps) => {
  const client = useApolloClient();
  const [saveUser] = useSaveUserMutation();
  const [loading, setLoading] = useState(false);
  const { user } = useCurrentUser();

  const {
    handleSubmit,
    setError,
    register: registerField,
    formState: { errors },
    resetField,
  } = useForm<SaveUserData>({
    resolver: yupResolver(saveUserValidation),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    },
  });

  const saveUserErrors: { [key: string]: ErrorOption } = {
    [UpdateUserResponseStatus.AlreadyTaken]: {
      message: t`Email already taken`,
    },
    [UpdateUserResponseStatus.InvalidCredentials]: {
      message: t`Invalid password`,
    },
  };

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    password,
    currentPassword,
  }: SaveUserData) => {
    setLoading(true);

    try {
      const { data } = await saveUser({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            password: password?.length ? password : undefined,
            currentPassword: currentPassword?.length
              ? currentPassword
              : undefined,
          },
        },
      });

      if (data?.saveUser?.status === UpdateUserResponseStatus.Success) {
        client.writeQuery({
          query: CurrentUserDocument,
          data: {
            me: {
              status: MeResponseStatus.Success,
              user: data?.saveUser?.user,
            },
          },
        });

        onSuccess && onSuccess(data?.saveUser);

        setLoading(false);

        resetField("password");
        resetField("currentPassword");

        return;
      }

      setError(
        data?.saveUser?.status === UpdateUserResponseStatus.InvalidCredentials
          ? "currentPassword"
          : "email",
        saveUserErrors[String(data?.saveUser?.status)]
      );
    } catch (exception) {
      console.error(exception);

      setError("email", {
        message: t`Something went wrong`,
      });
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate id={"account-form"}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            type={"text"}
            autoComplete="firstName"
            autoFocus
            disabled={loading}
            label={<Trans>First name</Trans>}
            helperText={errors.firstName?.message}
            error={!!errors.firstName?.message}
            {...registerField("firstName")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            type={"text"}
            autoComplete="lastName"
            disabled={loading}
            label={<Trans>Last name</Trans>}
            helperText={errors.lastName?.message}
            error={!!errors.lastName?.message}
            {...registerField("lastName")}
          />
        </Grid>
      </Grid>

      <TextField
        margin="normal"
        required
        fullWidth
        type={"email"}
        autoComplete="email"
        disabled={loading}
        label={<Trans>Email address</Trans>}
        helperText={errors.email?.message}
        error={!!errors.email?.message}
        {...registerField("email")}
      />

      <Typography sx={{ mt: 10 }}>
        <Trans>
          In order to change your password you have to fill in your current
          password
        </Trans>
      </Typography>

      <TextField
        margin="normal"
        fullWidth
        autoComplete="password"
        type={"password"}
        disabled={loading}
        label={<Trans>Current password</Trans>}
        helperText={errors.currentPassword?.message}
        error={!!errors.currentPassword?.message}
        {...registerField("currentPassword")}
      />

      <TextField
        margin="normal"
        fullWidth
        autoComplete="password"
        type={"password"}
        disabled={loading}
        label={<Trans>Password</Trans>}
        helperText={errors.password?.message}
        error={!!errors.password?.message}
        {...registerField("password")}
      />

      <LoadingButton
        loading={loading}
        disabled={loading}
        type={"submit"}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mt: 5 }}
      >
        <Trans>Update</Trans>
      </LoadingButton>
    </form>
  );
};

export default AccountForm;
