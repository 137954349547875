import { Trans } from "@lingui/macro";
import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useState } from "react";
import { SaveProjectMutationVariables } from "../../hooks.generated";
import useLocale from "../../hooks/useLocale";
import ProjectForm from "../forms/ProjectForm";

interface ProjectSaveDialogProps {
  project?: SaveProjectMutationVariables;
  open: boolean;
  onClose(): void;
}

const ProjectSaveDialog = ({
  project,
  open,
  onClose,
}: ProjectSaveDialogProps) => {
  const [loading, setLoading] = useState(false);
  const { navigate } = useLocale();

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      onClose={() => {
        if (loading) {
          return;
        }

        onClose();
      }}
      open={open}
    >
      <DialogTitle>
        {project?.projectId ? (
          <Trans>Edit project</Trans>
        ) : (
          <Trans>Create new project</Trans>
        )}

        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <ProjectForm
          project={project}
          onSuccess={(projectSaveResponse) => {
            if (!project?.projectId) {
              navigate(
                `/dashboard/projects/${projectSaveResponse?.project?.id}/hosts`
              );
            }

            onClose();
          }}
          onLoad={(loading) => {
            setLoading(loading);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ProjectSaveDialog;
