import { Theme } from "@emotion/react";
import { createTheme } from "@mui/material/styles";

export const theme: Theme = createTheme({
  backgroundColor: "#ededed",

  palette: {
    primary: {
      main: "#3098f3",
    },
    secondary: {
      main: "#224e38",
    },
    navigation: {
      main: "#000000de",
    },
  },

  typography: {
    fontFamily: "Montserrat",

    fontSize: 14,

    h1: {
      fontSize: 42,
    },
  },

  spacing: 4,

  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: "800px",
        maxWidthMd: "800px",
        maxWidthSm: "800px",
        maxWidthXl: "800px",
        maxWidthXs: "800px",
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: "#ffffff",
        },
      },
    },
  },
});
