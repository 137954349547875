import { Avatar, Chip } from "@mui/material";
import React from "react";
import { ReactComponent as SlackLogo } from "../../images/integrations/slack.svg";
import {
  AllIntegrationsForNotifyQuery,
  NotifyType,
  useAllIntegrationsForNotifyQuery,
} from "../../hooks.generated";
import { t } from "@lingui/macro";
import { AlternateEmail } from "@mui/icons-material";

interface NotifyChipProps {
  onRemove(index: number): void;
  onClick(): void;
  index: number;
  watch: any;
}

interface RenderNotifyProps {
  type: NotifyType;
  userData?: AllIntegrationsForNotifyQuery;
}

const renderNotify = ({
  userData,
  type,
  index,
  watch,
}: RenderNotifyProps & Omit<NotifyChipProps, "onRemove" | "onClick">) => {
  switch (type) {
    case NotifyType.Slack:
      const integration = userData?.me?.user?.integrations?.find(
        (integration) =>
          integration?.id === watch(`notify.${index}.integrationId`)
      );

      const channelId = watch(`notify.${index}.channelId`);

      if (!integration || !channelId) {
        return null;
      }

      const channel = integration?.channels?.find(
        (channel) => channel.id === channelId
      );

      return {
        icon: <SlackLogo />,
        label: channel ? `#${channel?.name}` : t`Channel not found`,
      };
    case NotifyType.Mail:
      const email = watch(`notify.${index}.email`);

      if (!email) {
        return null;
      }

      return {
        icon: <AlternateEmail />,
        label: email,
      };
  }

  return null;
};

const NotifyChip = ({ onRemove, onClick, index, watch }: NotifyChipProps) => {
  const { data } = useAllIntegrationsForNotifyQuery();

  const type = watch(`notify.${index}.type`);

  if (!type) {
    return null;
  }

  const notifyContent = renderNotify({ type, index, watch, userData: data });

  if (!notifyContent) {
    return null;
  }

  return (
    <Chip
      sx={{
        marginBottom: 1,
        marginRight: 1,
      }}
      onDelete={() => {
        onRemove(index);
      }}
      onClick={(event) => {
        onClick();
      }}
      label={notifyContent.label}
      icon={
        <Avatar
          sx={{
            padding: 2,
            bgcolor: "#fff",
            margin: 0,
            background: "transparent",
          }}
        >
          {notifyContent.icon}
        </Avatar>
      }
    />
  );
};

export default NotifyChip;
