import { Level } from "../hooks.generated";

export function getLevelColor(level: Level): string {
  switch (level) {
    case Level.Success:
      return "#5a9979";
    case Level.Warning:
      return "#cc4e1a";
    case Level.Error:
      return "#af271a";
  }
}
