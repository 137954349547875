import { Trans } from "@lingui/macro";
import { Box, Link as StyledLink, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import useLocale from "../../hooks/useLocale";
import PasswordResetForm from "../forms/PasswordResetForm";
import AuthLayout from "../layouts/AuthLayout";

const PasswordResetPage = () => {
  const { token } = useParams();
  const { localizePath } = useLocale();
  const [success, setSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <AuthLayout allowAuth={true} pageTitle={<Trans>Password reset</Trans>}>
      {success ? (
        <>
          <Typography sx={{ mb: 3 }}>
            <Trans>You have changed your password.</Trans>
          </Typography>

          <Box mt={1} mb={5}>
            <StyledLink
              fontSize={14}
              component={Link}
              to={localizePath("/login")}
            >
              <Trans>Click here to log in</Trans>
            </StyledLink>
          </Box>
        </>
      ) : (
        <>
          <Typography sx={{ mb: 3 }}>
            <Trans>Enter your new password.</Trans>
          </Typography>

          <PasswordResetForm
            token={String(token)}
            onSuccess={() => {
              setSuccess(true);
              enqueueSnackbar(<Trans>Password has been reset</Trans>);
            }}
          />

          <Box mt={1} mb={5}>
            <StyledLink
              fontSize={14}
              component={Link}
              to={localizePath("/login")}
            >
              <Trans>Did you remember your password?</Trans>
            </StyledLink>
          </Box>
        </>
      )}
    </AuthLayout>
  );
};

export default PasswordResetPage;
