export type StatusCodeColor =
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";

export const getColorForStatusCode = (statusCode: number): StatusCodeColor => {
  if (statusCode <= 0) {
    return "default";
  }

  if (statusCode >= 100 && statusCode <= 199) {
    return "info";
  } else if (statusCode >= 200 && statusCode <= 299) {
    return "success";
  } else if (statusCode >= 300 && statusCode <= 399) {
    return "secondary";
  } else if (statusCode >= 400 && statusCode <= 499) {
    return "warning";
  } else if (statusCode >= 500 && statusCode <= 599) {
    return "error";
  }

  return "default";
};

export const defaultStatusCodes = [
  ...(
    Array.from({
      length: 100,
    }) as unknown as number[]
  ).map((status, index) => 100 + index),
  ...(
    Array.from({
      length: 100,
    }) as unknown as number[]
  ).map((status, index) => 200 + index),
  ...(
    Array.from({
      length: 100,
    }) as unknown as number[]
  ).map((status, index) => 300 + index),
];
