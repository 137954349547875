import React, { useState } from "react";
import { ErrorOption, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, TextField } from "@mui/material";
import { Trans } from "@lingui/macro";
import {
  CurrentUserDocument,
  MeResponseStatus,
  RegisterResponse,
  RegisterResponseStatus,
  useRegisterMutation,
} from "../../hooks.generated";
import { t } from "@lingui/macro";
import { setCookie } from "../../utils/cookie";
import { useApolloClient } from "@apollo/client";

interface RegisterFormProps {
  onSuccess?(response: RegisterResponse): void;
}

interface RegisterData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const registerValidation = object({
  firstName: string().required(),
  lastName: string().required(),
  email: string().required().email(),
  password: string().required().min(5),
});

const RegisterForm = ({ onSuccess }: RegisterFormProps) => {
  const client = useApolloClient();
  const [register] = useRegisterMutation();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    setError,
    register: registerField,
    formState: { errors },
  } = useForm<RegisterData>({
    resolver: yupResolver(registerValidation),
  });

  const registerErrors: { [key: string]: ErrorOption } = {
    [RegisterResponseStatus.AlreadyTaken]: {
      message: t`Email already taken`,
    },
  };

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    password,
  }: RegisterData) => {
    setLoading(true);

    try {
      const { data } = await register({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            password,
          },
        },
      });

      if (data?.register?.status === RegisterResponseStatus.Success) {
        setCookie("token", String(data.register.token));

        client.writeQuery({
          query: CurrentUserDocument,
          data: {
            me: {
              status: MeResponseStatus.Success,
              user: data?.register?.user,
            },
          },
        });

        onSuccess && onSuccess(data?.register);

        return;
      }

      setError("email", registerErrors[String(data?.register?.status)]);
    } catch (exception) {
      console.error(exception);

      setError("email", {
        message: t`Something went wrong`,
      });
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            type={"text"}
            autoComplete="firstName"
            autoFocus
            disabled={loading}
            label={<Trans>First name</Trans>}
            helperText={errors.firstName?.message}
            error={!!errors.firstName?.message}
            {...registerField("firstName")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            type={"text"}
            autoComplete="lastName"
            disabled={loading}
            label={<Trans>Last name</Trans>}
            helperText={errors.lastName?.message}
            error={!!errors.lastName?.message}
            {...registerField("lastName")}
          />
        </Grid>
      </Grid>
      <TextField
        margin="normal"
        required
        fullWidth
        type={"email"}
        autoComplete="email"
        disabled={loading}
        label={<Trans>Email address</Trans>}
        helperText={errors.email?.message}
        error={!!errors.email?.message}
        {...registerField("email")}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        autoComplete="password"
        type={"password"}
        disabled={loading}
        label={<Trans>Password</Trans>}
        helperText={errors.password?.message}
        error={!!errors.password?.message}
        {...registerField("password")}
      />

      <Button
        type={"submit"}
        disabled={loading}
        fullWidth
        variant="contained"
        sx={{ mt: 5 }}
      >
        <Trans>Register</Trans>
      </Button>
    </form>
  );
};

export default RegisterForm;
