import { useApolloClient } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { ChevronLeft, ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Button,
  Paper,
  Link as StyledLink,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Divider,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { ReactNode, useState } from "react";
import { Link, To } from "react-router-dom";
import { ProjectByIdQuery } from "../../hooks.generated";
import useCurrentUser from "../../hooks/useCurrentUser";
import useLocale from "../../hooks/useLocale";
import { deleteCookie } from "../../utils/cookie";
import LanguagePicker from "../utils/LanguagePicker";
import Profile from "../../images/profile.svg";
import MenuIcon from "@mui/icons-material/Menu";

interface MainMenuProps {
  project?: ProjectByIdQuery["projectById"]["project"];
}

interface NavLink {
  link?: To | null;
  handle?(): void;
  name?: string | ReactNode;
}

const MainMenu = ({ project }: MainMenuProps) => {
  const client = useApolloClient();
  const { user } = useCurrentUser();
  const { localizePath, navigate } = useLocale();
  const [accountSubMenuAnchorEl, setAccountSubMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [menuActive, setMenuActive] = useState(false);
  const mobileMenu = useMediaQuery("(max-width: 899px)");

  const handleAccountSubMenuClose = () => {
    setAccountSubMenuAnchorEl(null);
  };

  const handleLogout = () => {
    deleteCookie("token");

    client.resetStore();

    navigate("/login");
  };

  const links: NavLink[] = [
    {
      link: "/dashboard",
      name: <Trans>Dashboard</Trans>,
    },
    {
      link: "/dashboard/projects",
      name: <Trans>Projects</Trans>,
    },
  ];

  if (project) {
    links.push({
      link: null,
    });

    links.push({
      link: "/dashboard/projects/" + project.id,
      name: project.name || <Trans>Project</Trans>,
    });

    links.push({
      link: "/dashboard/projects/" + project.id + "/hosts",
      name: <Trans>Hosts</Trans>,
    });
  }

  if (mobileMenu) {
    links.push({
      link: null,
    });

    links.push({
      link: "/dashboard/integrations",
      name: <Trans>Integrations</Trans>,
    });

    links.push({
      link: "/dashboard/account",
      name: <Trans>Account</Trans>,
    });

    links.push({
      handle: handleLogout,
      name: <Trans>Logout</Trans>,
    });
  }

  return (
    <Box sx={{ marginBottom: 2, marginTop: 2 }}>
      <Container component={"header"}>
        <Paper sx={{ p: 1, pt: 2, pb: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* // Normal menu */}
            <Box
              sx={{ display: { xs: "none", md: "flex", alignItems: "center" } }}
            >
              {links.map((link, linkIndex) => {
                if (!link.link) {
                  return (
                    <Box key={linkIndex} sx={{ ml: 1, mr: 1 }}>
                      |
                    </Box>
                  );
                }

                return (
                  <StyledLink
                    key={linkIndex}
                    component={Link}
                    to={localizePath(link.link)}
                    sx={{ textDecoration: "none" }}
                  >
                    <Button color="navigation">{link.name}</Button>
                  </StyledLink>
                );
              })}
            </Box>
            {/* Mobile menu */}

            <MenuIcon
              sx={{
                cursor: "pointer",
                marginLeft: 2,
                display: { sx: "inline-block", md: "none" },
              }}
              onClick={(event) => {
                setMenuActive(true);
              }}
            />

            <Drawer
              anchor={"left"}
              open={menuActive}
              onClose={() => setMenuActive(false)}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: 2,
                }}
              >
                <ChevronLeft
                  sx={{ marginLeft: "auto", cursor: "pointer" }}
                  onClick={() => {
                    setMenuActive(false);
                  }}
                />
              </Box>
              <List sx={{ minWidth: "240px" }}>
                {links.map((link, linkIndex) => {
                  if (link.link === null) {
                    return <Divider key={linkIndex} />;
                  }

                  const content = <ListItemText primary={link.name} />;

                  return (
                    <ListItem key={linkIndex} disablePadding>
                      {link.link ? (
                        <ListItemButton
                          component={Link}
                          to={localizePath(link.link)}
                          onClick={() => {
                            setMenuActive(false);
                          }}
                        >
                          {content}
                        </ListItemButton>
                      ) : (
                        <ListItemButton
                          onClick={() => {
                            if (link.handle) {
                              link.handle();
                            }

                            setMenuActive(false);
                          }}
                        >
                          {content}
                        </ListItemButton>
                      )}
                    </ListItem>
                  );
                })}
              </List>
            </Drawer>
            <Box
              sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
            >
              <LanguagePicker />

              {mobileMenu ? (
                <>
                  {user && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        marginLeft: 2,
                        marginRight: 0.5,
                      }}
                    >
                      <Link
                        to={localizePath("/dashboard/account")}
                        style={{ display: "flex" }}
                      >
                        <Box
                          component="img"
                          sx={{ width: "40px" }}
                          src={Profile}
                        />
                      </Link>
                    </Box>
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    marginLeft: 2,
                  }}
                  onClick={(event) => {
                    setAccountSubMenuAnchorEl(event?.currentTarget);
                  }}
                >
                  {user && (
                    <Box component="img" sx={{ width: "40px" }} src={Profile} />
                  )}

                  {!accountSubMenuAnchorEl ? <ExpandMore /> : <ExpandLess />}
                </Box>
              )}
            </Box>
            <Menu
              anchorEl={accountSubMenuAnchorEl}
              open={!!accountSubMenuAnchorEl}
              onClose={handleAccountSubMenuClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                component={Link}
                to={localizePath("/dashboard/account")}
                onClick={handleAccountSubMenuClose}
              >
                <Trans>Account</Trans>
              </MenuItem>

              <MenuItem
                component={Link}
                to={localizePath("/dashboard/integrations")}
                onClick={handleAccountSubMenuClose}
              >
                <Trans>Integrations</Trans>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleLogout();
                }}
              >
                <Trans>Logout</Trans>
              </MenuItem>
            </Menu>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default MainMenu;
