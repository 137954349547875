import React, { useState } from 'react'
import { ErrorOption, useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, TextField } from '@mui/material'
import { t, Trans } from '@lingui/macro'
import {
  CurrentUserDocument,
  LoginResponse,
  LoginResponseStatus,
  MeResponseStatus,
  useLoginMutation,
} from '../../hooks.generated'
import { setCookie } from '../../utils/cookie'
import { useApolloClient } from '@apollo/client'

interface LoginFormProps {
  onSuccess?(response: LoginResponse): void
}

interface LoginData {
  email: string
  password: string
}

const loginValidation = object({
  email: string().required().email(),
  password: string().required().min(5),
})

const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const client = useApolloClient()
  const [login] = useLoginMutation()
  const [loading, setLoading] = useState(false)
  const {
    handleSubmit,
    setError,
    register: registerField,
    formState: { errors },
  } = useForm<LoginData>({
    resolver: yupResolver(loginValidation),
  })

  const loginErrors: { [key: string]: ErrorOption } = {
    [LoginResponseStatus.InvalidCredentials]: {
      message: t`Invalid credentials`,
    },
  }

  const onSubmit = async ({ email, password }: LoginData) => {
    setLoading(true)

    try {
      const { data } = await login({
        variables: {
          input: {
            email,
            password,
          },
        },
      })

      if (data?.login?.status === LoginResponseStatus.Success) {
        setCookie('token', String(data.login.token))

        client.writeQuery({
          query: CurrentUserDocument,
          data: {
            me: {
              status: MeResponseStatus.Success,
              user: data?.login?.user
            },
          },
        })

        onSuccess && onSuccess(data?.login)

        return
      }

      setError('email', loginErrors[String(data?.login?.status)])
    } catch (exception) {
      console.error(exception)

      setError('email', {
        message: t`Something went wrong`,
      })
    }

    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextField
        margin='normal'
        required
        fullWidth
        type={'email'}
        autoComplete='email'
        autoFocus
        disabled={loading}
        label={<Trans>Email address</Trans>}
        helperText={errors.email?.message}
        error={!!errors.email?.message}
        {...registerField('email')}
      />

      <TextField
        margin='normal'
        required
        fullWidth
        autoComplete='password'
        type={'password'}
        disabled={loading}
        label={<Trans>Password</Trans>}
        helperText={errors.password?.message}
        error={!!errors.password?.message}
        {...registerField('password')}
      />

      <Button
        type={'submit'}
        disabled={loading}
        fullWidth
        variant='contained'
        sx={{ mt: 5 }}
      >
        <Trans>Login</Trans>
      </Button>
    </form>
  )
}

export default LoginForm