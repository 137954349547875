import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Paper, Typography } from "@mui/material";
import React from "react";
import { getLevelColor } from "../../utils/getLevelColor";

interface TimelineTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

const TimelineTooltip = ({ active, payload, label }: TimelineTooltipProps) => {
  const { i18n } = useLingui();

  if (!active || !payload || payload.length <= 0) {
    return null;
  }

  const date = new Date(payload[0].payload.date * 1000);

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Typography sx={{ fontSize: 17 }}>
        {i18n.date(date, {
          timeStyle: "medium",
          dateStyle: "short",
        })}
      </Typography>

      <table>
        <tbody>
          {payload.map((payloadItem, index) => {
            const took = payloadItem.payload[payloadItem.dataKey];

            return (
              <tr
                key={index}
                style={{
                  color: getLevelColor(
                    payloadItem.payload[
                      payloadItem.dataKey.replace("Took", "Level")
                    ]
                  ),
                }}
              >
                <td>
                  <Typography sx={{ fontSize: 14 }}>
                    {payloadItem.name}:
                  </Typography>
                </td>
                <td style={{ textAlign: "right", paddingLeft: 5 }}>
                  <Typography sx={{ fontSize: 14 }}>
                    <strong>{took}</strong>
                    <Trans>ms</Trans>
                  </Typography>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Paper>
  );
};

export default TimelineTooltip;
