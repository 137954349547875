import React from "react";
import { Box } from "@mui/system";
import SuccessStatus from "../../images/notifications/success.svg";
import WarningStatus from "../../images/notifications/warning.svg";
import ErrorStatus from "../../images/notifications/error.svg";
import { Level } from "../../hooks.generated";

interface LevelIconProps {
  level: Level;
}

const LevelIcon = ({ level }: LevelIconProps) => {
  return (
    <>
      {level === Level.Success && (
        <Box component="img" sx={{ width: "40px" }} src={SuccessStatus} />
      )}
      {level === Level.Warning && (
        <Box component="img" sx={{ width: "40px" }} src={WarningStatus} />
      )}
      {level === Level.Error && (
        <Box component="img" sx={{ width: "40px" }} src={ErrorStatus} />
      )}
    </>
  );
};

export default LevelIcon;
