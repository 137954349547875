import { useReactiveVar } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, MenuItem, Select } from "@mui/material";
import React, { ReactElement } from "react";
import ReactCountryFlag from "react-country-flag";
import useLocale from "../../hooks/useLocale";
import { localeVar } from "../../utils/localeVar";

interface Locale {
  name: ReactElement;
  icon: string;
}

export const locales: { [key: string]: Locale } = {
  en: {
    name: <Trans>English</Trans>,
    icon: "gb",
  },
  nl: {
    name: <Trans>Dutch</Trans>,
    icon: "nl",
  },
};

const LanguagePicker = () => {
  const currentLocale = useReactiveVar(localeVar);
  const { navigate } = useLocale();

  return (
    <Select
      value={currentLocale}
      size={"small"}
      onChange={(event) => {
        const newLocale = String(event.target.value);

        localeVar(newLocale);
        navigate(
          window.location.pathname.substring(
            3,
            window.location.pathname.length
          ) + window.location.search,
          newLocale
        );
      }}
      renderValue={(value) => {
        return (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <>
              <ReactCountryFlag countryCode={locales[value].icon} svg />
              {locales[value].name}
            </>
          </Box>
        );
      }}
    >
      {Object.entries(locales).map(([locale]) => (
        <MenuItem key={locale} value={locale}>
          {locales[locale].name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguagePicker;
