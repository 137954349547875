import { Trans } from "@lingui/macro";
import React from "react";
import { Level, Project } from "../../hooks.generated";
import useLocale from "../../hooks/useLocale";
import {
  Alert,
  Card,
  CardHeader,
  Grid,
  Link as StyledLink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { translateNotificationType } from "../../translations/notificationTypes";
import LevelIcon from "../utils/LevelIcon";

interface DashboardHost {
  id: String;
  projectId: String;
  name: String;
  state: Level;
  message: String | null;
}

interface DashboardHostCheckNotification {
  level: Level;
  message: String | null;
}

interface HostsStatusProps {
  loading: boolean;
  projects?: Project[];
}

const HostsStatus = ({ projects, loading }: HostsStatusProps) => {
  const { localizePath } = useLocale();

  const hosts = (projects || []).reduce<DashboardHost[]>((hosts, project) => {
    for (const host of project?.hosts || []) {
      const mostImportantCheck = (
        host?.checks || []
      ).reduce<DashboardHostCheckNotification | null>(
        (mostImportantCheck, check) => {
          if (mostImportantCheck && mostImportantCheck.level === Level.Error) {
            return mostImportantCheck;
          }

          if (!check) {
            return mostImportantCheck;
          }

          // todo: can we remove the any?
          const results = (check?.stats as any)?.statusResults?.items || [];

          if (results.length) {
            const lastResult = results[0];

            if (!lastResult) {
              return mostImportantCheck;
            }

            const level = lastResult.level || Level.Success;

            if (
              level === Level.Success &&
              mostImportantCheck &&
              mostImportantCheck.level !== Level.Success
            ) {
              return mostImportantCheck;
            }

            return {
              level,
              message:
                level !== Level.Success
                  ? translateNotificationType(lastResult.type)
                  : null,
            };
          }

          return mostImportantCheck;
        },
        null
      );

      hosts.push({
        id: host.id,
        projectId: project.id,
        name: host.hostname,
        state: mostImportantCheck?.level || Level.Success,
        message: mostImportantCheck?.message || null,
      });
    }

    return hosts;
  }, []);

  return (
    <Grid container spacing={4}>
      {!loading && !hosts.length && (
        <Grid item xs={12}>
          <Alert severity="info">
            <Trans>No hosts found.</Trans>
          </Alert>
        </Grid>
      )}

      {hosts.map((host, hostIndex) => (
        <Grid item xs={12} sm={6} lg={4} key={hostIndex}>
          <StyledLink
            component={Link}
            underline="none"
            sx={{ height: "100%", display: "flex", width: "100%" }}
            to={localizePath(
              `/dashboard/projects/${host.projectId}/hosts/${host.id}`
            )}
          >
            <Card sx={{ height: "100%", display: "flex", width: "100%" }}>
              <CardHeader
                sx={{ width: "100%" }}
                avatar={<LevelIcon level={host.state} />}
                title={host.name}
                subheader={<>{!!host.message && host.message}</>}
              />
            </Card>
          </StyledLink>
        </Grid>
      ))}
    </Grid>
  );
};

export default HostsStatus;
