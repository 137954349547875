import { useParams } from "react-router-dom";
import { useProjectByIdQuery } from "../hooks.generated";

export default function useCurrentProject() {
  const { projectId } = useParams();

  const { data, loading, error } = useProjectByIdQuery({
    skip: !projectId,
    variables: {
      projectId: String(projectId),
    },
  });

  if (loading) {
    return {
      loading,
    };
  }

  if (!data?.projectById.project || error || !projectId) {
    return {
      project: null,
      loading,
    };
  }

  return {
    project: data?.projectById?.project,
    loading,
  };
}
