import { Box, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { TimelineRange } from "../../utils/getTimelineRange";
import { useLingui } from "@lingui/react";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Trans } from "@lingui/macro";

interface HostTimelineRangeSelectionProps {
  timelineRange: TimelineRange;
  disabled: boolean;
  onTimelineUpdate(updatedTimeline: TimelineRange): void;
}

interface LocalTimelineRange {
  start: Dayjs;
  end: Dayjs;
}

const HostTimelineRangeSelection = ({
  disabled,
  timelineRange,
  onTimelineUpdate,
}: HostTimelineRangeSelectionProps) => {
  const { i18n } = useLingui();

  const [localTimelineRange, setLocalTimelineRange] =
    useState<LocalTimelineRange>(() => {
      return {
        start: dayjs(timelineRange.timelineStart * 1000),
        end: dayjs(timelineRange.timelineEnd * 1000),
      };
    });

  useEffect(() => {
    setLocalTimelineRange({
      start: dayjs(timelineRange.timelineStart * 1000),
      end: dayjs(timelineRange.timelineEnd * 1000),
    });
  }, [timelineRange]);

  const updateValue = (start: Dayjs, end: Dayjs) => {
    const startUnix = start.unix();
    const endUnix = end.unix();

    onTimelineUpdate({
      timelineStart: startUnix,
      timelineEnd: endUnix,
    });
  };

  return (
    <Box sx={{ width: { xs: "100%" }, display: { xs: "flex" } }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDateTimePicker
          value={localTimelineRange.start}
          onChange={(newValue) => {
            updateValue(dayjs(newValue), localTimelineRange.end);
          }}
          label={<Trans>Select a start time</Trans>}
          closeOnSelect={false}
          renderInput={(params) => {
            return (
              <Chip
                onClick={params.onClick}
                disabled={disabled}
                icon={<CalendarMonthIcon />}
                label={i18n.date(params?.inputProps?.value, {
                  timeStyle: "short",
                  dateStyle: "short",
                })}
                sx={{ marginRight: 2, width: { xs: "100%" } }}
              />
            );
          }}
        />

        <MobileDateTimePicker
          value={localTimelineRange.end}
          onChange={(newValue) => {
            updateValue(localTimelineRange.start, dayjs(newValue));
          }}
          label={<Trans>Select a end time</Trans>}
          closeOnSelect={false}
          renderInput={(params) => {
            return (
              <Chip
                onClick={params.onClick}
                disabled={disabled}
                icon={<CalendarMonthIcon />}
                label={i18n.date(params?.inputProps?.value, {
                  timeStyle: "short",
                  dateStyle: "short",
                })}
                sx={{
                  width: { xs: "100%" },
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default HostTimelineRangeSelection;
