import { Trans } from "@lingui/macro";
import { Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { ReactNode, useState } from "react";

interface DeleteWithConfirmationProps {
  title: ReactNode;
  onConfirm(): void;
}

const DeleteWithConfirmation = ({
  title,
  onConfirm,
}: DeleteWithConfirmationProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog
        onClick={(event) => event.preventDefault()}
        maxWidth="xs"
        open={open}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent dividers>
          <Typography>
            <Trans>Changes cannot be undone</Trans>
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            <Trans>Cancel</Trans>
          </Button>

          <Button
            color="error"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
          >
            <Trans>Delete</Trans>
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton
        onClick={(event) => {
          event.preventDefault();

          setOpen(true);
        }}
      >
        <Delete />
      </IconButton>
    </>
  );
};

export default DeleteWithConfirmation;
