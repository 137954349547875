import React, { useState } from "react";
import { ErrorOption, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import {
  ResetPasswordResponseStatus,
  useResetPasswordMutation,
} from "../../hooks.generated";

interface ResetPasswordFormProps {
  token: string;
  onSuccess?(): void;
}

interface ResetPasswordData {
  password: string;
}

const resetPasswordValidation = object({
  password: string().required().min(5),
});

const PasswordResetForm = ({ token, onSuccess }: ResetPasswordFormProps) => {
  const [resetPassword] = useResetPasswordMutation();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    setError,
    register: registerField,
    formState: { errors },
  } = useForm<ResetPasswordData>({
    resolver: yupResolver(resetPasswordValidation),
  });

  const resetPasswordErrors: { [key: string]: ErrorOption } = {
    [ResetPasswordResponseStatus.NotFound]: {
      message: t`Invalid password reset link`,
    },
    [ResetPasswordResponseStatus.Expired]: {
      message: t`Password reset link is expired`,
    },
    [ResetPasswordResponseStatus.SomethingWentWrong]: {
      message: t`Something went wrong`,
    },
  };

  const onSubmit = async ({ password }: ResetPasswordData) => {
    setLoading(true);

    try {
      const { data } = await resetPassword({
        variables: {
          token,
          input: {
            password,
          },
        },
      });

      if (data?.resetPassword?.status === ResetPasswordResponseStatus.Success) {
        onSuccess && onSuccess();

        return;
      }

      setError(
        "password",
        resetPasswordErrors[String(data?.resetPassword?.status)]
      );
    } catch (exception) {
      console.error(exception);

      setError("password", {
        message: t`Something went wrong`,
      });
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextField
        margin="normal"
        required
        fullWidth
        autoComplete="password"
        type={"password"}
        disabled={loading}
        label={<Trans>Password</Trans>}
        helperText={errors.password?.message}
        error={!!errors.password?.message}
        {...registerField("password")}
      />

      <Button
        type={"submit"}
        disabled={loading}
        fullWidth
        variant="contained"
        sx={{ mt: 5 }}
      >
        <Trans>Reset password</Trans>
      </Button>
    </form>
  );
};

export default PasswordResetForm;
