import { t, Trans } from "@lingui/macro";
import {
  Alert,
  Avatar,
  Backdrop,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import {
  useAllIntegrationsQuery,
  SlackIntegration,
  useInstallSlackAppMutation,
  InstallSlackAppResponseStatus,
  useDeleteIntegrationMutation,
  DeleteIntegrationResponseStatus,
} from "../../../hooks.generated";
import PageHeader from "../../../styles/PageHeader";
import DashboardLayout from "../../layouts/DashboardLayout";
import { ReactComponent as SlackLogo } from "../../../images/integrations/slack.svg";
import { Add, Close } from "@mui/icons-material";
import { useState } from "react";
import { useSnackbar } from "notistack";
import useLocale from "../../../hooks/useLocale";
import DashboardErrorPage from "./DashboardErrorPage";
import DeleteWithConfirmation from "../../utils/DeleteWithConfirmation";
import { useApolloClient } from "@apollo/client";

const integrationOptions = {
  SlackIntegration(integration: SlackIntegration) {
    return {
      logo: <SlackLogo />,
      title: t`Slack integration`,
      subHeader: (
        <>
          <Trans>Workspace</Trans>: {integration.team}
        </>
      ),
    };
  },
};

const addIntegrationErrors: { [key: string]: String } = {
  [InstallSlackAppResponseStatus.SomethingWentWrong]: t`Something went wrong`,
  [InstallSlackAppResponseStatus.Unauthenticated]: t`Unauthenticated`,
};

const IntegrationsPage = () => {
  const client = useApolloClient();
  const { locale } = useLocale();
  const { data, loading } = useAllIntegrationsQuery();
  const [open, setOpen] = useState(false);
  const [integrationAddLoading, setIntegrationAddLoading] = useState(false);
  const [installSlackApp] = useInstallSlackAppMutation();
  const [deleteIntegration] = useDeleteIntegrationMutation();
  const { enqueueSnackbar } = useSnackbar();

  const integrations = data?.me?.user?.integrations || null;

  if (!loading && !integrations) {
    return (
      <DashboardErrorPage
        message={<Trans>Failed to load integrations</Trans>}
      />
    );
  }

  return (
    <DashboardLayout
      loading={loading}
      pageHeader={
        <PageHeader
          title={<Trans>Integrations</Trans>}
          breadcrumbs={[
            {
              to: "/dashboard",
              name: <Trans>Dashboard</Trans>,
            },
          ]}
          actions={
            <Button
              variant="contained"
              startIcon={<Add />}
              disableRipple
              disableElevation
              disableFocusRipple
              sx={{
                width: { xs: "100%", sm: "auto" },
                marginLeft: "auto",
                marginBottom: {
                  xs: 3,
                },
              }}
              onClick={() => setOpen(true)}
            >
              <Trans>Add integration</Trans>
            </Button>
          }
        />
      }
    >
      <Container component={"main"}>
        {(integrations || []).length <= 0 && (
          <Alert severity="info">
            <Trans>No integrations found.</Trans>
          </Alert>
        )}

        {integrations?.map((integration) => {
          if (!integration) {
            return null;
          }

          return (
            <Card
              key={integration.id}
              sx={{
                marginBottom: 3,
              }}
            >
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "#fff" }}>
                    {
                      integrationOptions[integration.__typename](integration)
                        .logo
                    }
                  </Avatar>
                }
                title={
                  integrationOptions[integration.__typename](integration).title
                }
                subheader={
                  integrationOptions[integration.__typename](integration)
                    .subHeader
                }
                action={
                  <DeleteWithConfirmation
                    title={
                      <Trans>
                        Are you sure you want to delete this integration?
                      </Trans>
                    }
                    onConfirm={async () => {
                      try {
                        const response = await deleteIntegration({
                          variables: {
                            integrationId: integration.id,
                          },
                        });

                        if (
                          response.data?.deleteIntegration.status ===
                          DeleteIntegrationResponseStatus.Success
                        ) {
                          client.cache.evict({
                            id: integration.__typename + ":" + integration.id,
                          });

                          enqueueSnackbar(<Trans>Deleted integration</Trans>);

                          return;
                        }
                      } catch (exception) {
                        console.error(exception);
                      }

                      enqueueSnackbar(
                        <Trans>Failed to delete integration</Trans>
                      );
                    }}
                  />
                }
              />
            </Card>
          );
        })}
      </Container>

      <Dialog
        maxWidth={"xs"}
        fullWidth
        onClose={() => {
          if (integrationAddLoading) {
            return;
          }

          setOpen(false);
        }}
        open={open}
      >
        <DialogTitle>
          <Trans>Add new integration</Trans>

          <IconButton
            onClick={() => {
              if (integrationAddLoading) {
                return;
              }

              setOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <List sx={{ pt: 0 }}>
          <ListItem
            button
            onClick={async () => {
              setIntegrationAddLoading(true);

              try {
                const response = await installSlackApp({
                  variables: {
                    locale: locale || "en",
                  },
                });

                if (
                  response.data?.installSlackApp.status ===
                  InstallSlackAppResponseStatus.Success
                ) {
                  window.open(
                    String(response.data.installSlackApp.redirectTo),
                    "_newtab"
                  );

                  setOpen(false);
                } else {
                  enqueueSnackbar(
                    addIntegrationErrors[
                      response.data?.installSlackApp.status ||
                        InstallSlackAppResponseStatus.SomethingWentWrong
                    ]
                  );
                }
              } catch (exception) {
                enqueueSnackbar(
                  addIntegrationErrors[
                    InstallSlackAppResponseStatus.SomethingWentWrong
                  ]
                );
              }

              setIntegrationAddLoading(false);
            }}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#fff", padding: 1 }}>
                <SlackLogo />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Slack"} />
          </ListItem>
        </List>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: 1401 }}
        open={integrationAddLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
};

export default IntegrationsPage;
