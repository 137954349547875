import { Trans } from "@lingui/macro";
import { Add, Remove } from "@mui/icons-material";
import { Box, Grid, IconButton, TextField } from "@mui/material";
import React from "react";
import { useFieldArray } from "react-hook-form";

interface HostCheckHeadersProps {
  registerField: any; // todo: can we remove the any?
  control: any; // todo: can we remove the any?
  loading: boolean;
}

const HostCheckHeaders = ({
  control,
  registerField,
  loading,
}: HostCheckHeadersProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "checks.http.headers",
  });

  return (
    <Box>
      {fields.map((field, index) => {
        return (
          <Grid container spacing={1} sx={{ mb: 2 }} key={index}>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="none"
                fullWidth
                type={"text"}
                size={"small"}
                disabled={loading}
                label={<Trans>Name</Trans>}
                {...registerField("checks.http.headers." + index + ".name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex" }}>
                <TextField
                  margin="none"
                  fullWidth
                  type={"text"}
                  size={"small"}
                  disabled={loading}
                  label={<Trans>Value</Trans>}
                  {...registerField(
                    "checks.http.headers." + index + ".value"
                  )}
                />

                <IconButton
                  color={"primary"}
                  type={"button"}
                  disabled={loading}
                  sx={{ ml: 1 }}
                  onClick={() => {
                    remove(index);

                    if (fields.length === 1) {
                      append({
                        name: "",
                        value: "",
                      });
                    }
                  }}
                >
                  <Remove />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        );
      })}

      <Box sx={{ textAlign: "right" }}>
        <IconButton
          color={"primary"}
          type={"button"}
          disabled={loading}
          onClick={() => {
            append({
              name: "",
              value: "",
            });
          }}
        >
          <Add />
        </IconButton>
      </Box>
    </Box>
  );
};

export default HostCheckHeaders;
