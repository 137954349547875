import { ApolloProvider, useReactiveVar } from "@apollo/client";
import { ThemeProvider } from "@emotion/react";
import React, { PropsWithChildren, useEffect } from "react";
import { theme } from "../styles/theme";
import { useApollo } from "../utils/apolloClient";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { nl, en } from "make-plural/plurals";
import getCatalog from "../locales/catalogs";
import { SnackbarProvider } from "notistack";
import { localeVar } from "../utils/localeVar";

const changeLanguage = (locale: string) => {
  const catalog = getCatalog(locale);

  i18n.loadLocaleData(locale, {
    plurals: { nl, en }[`${locale}`],
  });

  i18n.load({
    [locale]: catalog.messages,
  });

  i18n.activate(locale);
};

const AppProviders = ({ children }: PropsWithChildren<{}>) => {
  const apolloClient = useApollo();
  const locale = useReactiveVar(localeVar);

  useEffect(() => {
    changeLanguage(locale);
  }, [locale]);

  return (
    <>
      <ApolloProvider client={apolloClient}>
        <I18nProvider forceRenderOnLocaleChange={false} i18n={i18n}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>{children}</SnackbarProvider>
          </ThemeProvider>
        </I18nProvider>
      </ApolloProvider>
    </>
  );
};

export default AppProviders;
