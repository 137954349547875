import { useCurrentUserQuery } from "../hooks.generated";
import { deleteCookie, getCookie } from "../utils/cookie";

export default function useCurrentUser() {
  const { data, loading, error } = useCurrentUserQuery({
    skip: !getCookie("token"),
  });

  const token = getCookie("token");

  if (loading) {
    return {
      loading,
    };
  }

  if (!data?.me || error || !token || !data?.me?.user) {
    deleteCookie("token");

    return {
      user: null,
      loading,
    };
  }

  return {
    user: data?.me?.user,
    token,
    loading,
  };
}
