import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import {
  ForgotPasswordResponse,
  ForgotPasswordResponseStatus,
  useForgotPasswordMutation,
} from "../../hooks.generated";
import useLocale from "../../hooks/useLocale";

interface ForgotPasswordFormProps {
  onSuccess?(response: ForgotPasswordResponse): void;
}

interface ForgotPasswordData {
  email: string;
}

const forgotPasswordValidation = object({
  email: string().required().email(),
});

const ForgotPasswordForm = ({ onSuccess }: ForgotPasswordFormProps) => {
  const { locale } = useLocale();
  const [forgotPassword] = useForgotPasswordMutation();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    setError,
    register: registerField,
    formState: { errors },
  } = useForm<ForgotPasswordData>({
    resolver: yupResolver(forgotPasswordValidation),
  });

  const onSubmit = async ({ email }: ForgotPasswordData) => {
    setLoading(true);

    try {
      const { data } = await forgotPassword({
        variables: {
          locale: locale || "en",
          input: {
            email,
          },
        },
      });

      if (
        data?.forgotPassword?.status === ForgotPasswordResponseStatus.Success
      ) {
        onSuccess && onSuccess(data?.forgotPassword);
      } else {
        setError("email", {
          message: t`Something went wrong`,
        });
      }
    } catch (exception) {
      console.error(exception);

      setError("email", {
        message: t`Something went wrong`,
      });
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextField
        margin="normal"
        required
        fullWidth
        type={"email"}
        autoComplete="email"
        autoFocus
        disabled={loading}
        label={<Trans>Email address</Trans>}
        helperText={errors.email?.message}
        error={!!errors.email?.message}
        {...registerField("email")}
      />

      <Button
        type={"submit"}
        disabled={loading}
        fullWidth
        variant="contained"
        sx={{ mt: 5 }}
      >
        <Trans>Send password reminder</Trans>
      </Button>
    </form>
  );
};

export default ForgotPasswordForm;
