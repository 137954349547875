import { Trans } from "@lingui/macro";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  Container,
  Link as StyledLink,
} from "@mui/material";
import {
  DeleteHostResponseStatus,
  useAllHostsQuery,
  useDeleteHostMutation,
} from "../../../hooks.generated";
import PageHeader from "../../../styles/PageHeader";
import DashboardLayout from "../../layouts/DashboardLayout";
import useLocale from "../../../hooks/useLocale";
import { Link, useParams } from "react-router-dom";
import Add from "@mui/icons-material/Add";
import { useState } from "react";
import HostSaveDialog from "../../dialogs/HostSaveDialog";
import DashboardErrorPage from "./DashboardErrorPage";
import { useApolloClient } from "@apollo/client";
import DeleteWithConfirmation from "../../utils/DeleteWithConfirmation";
import { useSnackbar } from "notistack";
import { getTimelineRange } from "../../../utils/getTimelineRange";

const HostsPage = () => {
  const client = useApolloClient();
  const { localizePath } = useLocale();
  const { projectId } = useParams();
  const [showCreateDialog, createHost] = useState(false);
  const [deleteHost] = useDeleteHostMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [timelineRange] = useState(() => {
    return getTimelineRange(24 * 60 * 60);
  });

  const { data, loading } = useAllHostsQuery({
    variables: {
      projectId: String(projectId),
    },
  });

  const project = data?.projectById?.project;
  const hosts = project?.hosts || null;

  if (!loading && (!hosts || !project)) {
    return <DashboardErrorPage message={<Trans>Failed to load hosts</Trans>} />;
  }

  return (
    <DashboardLayout
      loading={loading}
      pageHeader={
        <PageHeader
          title={<Trans>Hosts</Trans>}
          breadcrumbs={[
            {
              to: "/dashboard",
              name: <Trans>Dashboard</Trans>,
            },
            {
              to: "/dashboard/projects",
              name: <Trans>Projects</Trans>,
            },
            {
              to: `/dashboard/projects/${project?.id}`,
              name: project?.name,
            },
          ]}
          actions={
            <Button
              variant="contained"
              startIcon={<Add />}
              disableRipple
              disableElevation
              disableFocusRipple
              sx={{
                width: { xs: "100%", sm: "auto" },
                marginLeft: "auto",
                marginBottom: {
                  xs: 3,
                },
              }}
              onClick={() => createHost(true)}
            >
              <Trans>Create host</Trans>
            </Button>
          }
        />
      }
    >
      <Container component={"main"}>
        {(hosts || []).length <= 0 && (
          <Alert severity="info">
            <Trans>No hosts found.</Trans>
          </Alert>
        )}

        {hosts?.map((host) => {
          if (!host) {
            return null;
          }

          return (
            <StyledLink
              component={Link}
              underline="none"
              to={localizePath(
                `/dashboard/projects/${project?.id}/hosts/${host.id}`
              )}
              key={host.id}
            >
              <Card
                sx={{
                  marginBottom: 3,
                }}
              >
                <CardHeader
                  title={host.name}
                  subheader={host.hostname}
                  action={
                    <DeleteWithConfirmation
                      title={
                        <Trans>
                          Are you sure you want to delete this host?
                        </Trans>
                      }
                      onConfirm={async () => {
                        try {
                          const response = await deleteHost({
                            variables: {
                              hostId: host.id,
                            },
                          });

                          if (
                            response.data?.deleteHost.status ===
                            DeleteHostResponseStatus.Success
                          ) {
                            client.cache.evict({
                              id: "Host:" + host.id,
                            });

                            enqueueSnackbar(<Trans>Deleted host</Trans>);

                            return;
                          }
                        } catch (exception) {
                          console.error(exception);
                        }

                        enqueueSnackbar(<Trans>Failed to delete host</Trans>);
                      }}
                    />
                  }
                />
              </Card>
            </StyledLink>
          );
        })}
      </Container>

      {project && (
        <HostSaveDialog
          projectId={project.id}
          open={showCreateDialog}
          timelineRange={timelineRange}
          onClose={() => {
            createHost(false);
          }}
        />
      )}
    </DashboardLayout>
  );
};

export default HostsPage;
