import { Trans } from "@lingui/macro";
import { Container } from "@mui/material";
import PageHeader from "../../../styles/PageHeader";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Project, useDashboardHostsQuery } from "../../../hooks.generated";
import HostsStatus from "../../dashboard/HostsStatus";
import { useState } from "react";
import { getInfiniteStatusResultTimelineRange } from "../../../utils/getTimelineRange";

const Home = () => {
  const [statusResultTimelineRange] = useState(() => {
    return getInfiniteStatusResultTimelineRange();
  });

  const { data, loading } = useDashboardHostsQuery({
    fetchPolicy: "network-only",
    variables: {
      ...statusResultTimelineRange,
    },
  });

  return (
    <DashboardLayout
      loading={loading}
      pageHeader={<PageHeader title={<Trans>Dashboard</Trans>} />}
    >
      <Container component={"main"}>
        <HostsStatus
          projects={data?.me.user?.projects as Project[]}
          loading={loading}
        />
      </Container>
    </DashboardLayout>
  );
};

export default Home;
