import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import React from "react";

interface PaperLoadingProps {
  loading: boolean;
}

const StyledLoadingWrapper = styled.div`
  background-color: rgba(1, 1, 1, 0.2);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 4px;
`;

const PaperLoading = ({ loading }: PaperLoadingProps) => {
  if (!loading) {
    return null;
  }

  return (
    <StyledLoadingWrapper>
      <CircularProgress />
    </StyledLoadingWrapper>
  );
};

export default PaperLoading;
