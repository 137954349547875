import { Trans } from "@lingui/macro";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import React, { PropsWithChildren, useState } from "react";
import { defaultStatusCodes } from "../../utils/statusCode";

interface EditHostCheckStatusCodesProps {
  watch: any;
  setValue: any;
}

const EditHostCheckStatusCodes = ({
  children,
  watch,
  setValue,
}: PropsWithChildren<EditHostCheckStatusCodesProps>) => {
  const [editStatusCodes, setEditStatusCodes] = useState(false);

  const onClose = () => {
    setEditStatusCodes(false);
  };

  const statusCodeGroups = [
    {
      name: <Trans>Informational responses</Trans>,
      start: 100,
    },
    {
      name: <Trans>Successful responses</Trans>,
      start: 200,
    },
    {
      name: <Trans>Redirection messages</Trans>,
      start: 300,
    },
    {
      name: <Trans>Client error responses</Trans>,
      start: 400,
    },
    {
      name: <Trans>Server error responses</Trans>,
      start: 500,
    },
  ];

  const selectedStatusCodes = watch("checks.http.statusCodes");

  const isSelected = (code: number) => {
    if (selectedStatusCodes.length === 0) {
      return defaultStatusCodes.indexOf(code) !== -1;
    }

    return selectedStatusCodes.indexOf(code) !== -1;
  };

  return (
    <>
      {React.cloneElement(children as any, {
        onClick() {
          setEditStatusCodes(true);
        },
      })}
      <Dialog
        maxWidth={"sm"}
        fullWidth
        onClose={() => {
          onClose();
        }}
        open={editStatusCodes}
      >
        <DialogTitle>
          <Trans>Configure HTTP status codes</Trans>

          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {statusCodeGroups.map((statusCode) => {
            const codes = (
              Array.from({
                length: 100,
              }) as unknown as number[]
            ).map((status, index) => statusCode.start + index);

            const allSelected = codes.every((code) => isSelected(code));

            return (
              <Box key={statusCode.start}>
                <FormControlLabel
                  label={
                    <>
                      {statusCode.name}{" "}
                      <small>
                        ({statusCode.start} - {statusCode.start + 99})
                      </small>
                    </>
                  }
                  control={
                    <Checkbox
                      onChange={() => {
                        const newStatusCodes = selectedStatusCodes.length
                          ? [...selectedStatusCodes]
                          : [...defaultStatusCodes];

                        for (const statusCodeToAdd of codes) {
                          const statusIndex =
                            newStatusCodes.indexOf(statusCodeToAdd);

                          if (allSelected) {
                            if (statusIndex !== -1) {
                              newStatusCodes.splice(statusIndex, 1);
                            }
                          } else {
                            if (statusIndex === -1) {
                              newStatusCodes.push(statusCodeToAdd);
                            }
                          }
                        }

                        setValue("checks.http.statusCodes", newStatusCodes);
                      }}
                      checked={allSelected}
                    />
                  }
                />

                <Box sx={{ ml: 3 }}>
                  {codes.map((code) => (
                    <FormControlLabel
                      label={code}
                      sx={{ width: "59px" }}
                      key={code}
                      control={
                        <Checkbox
                          size="small"
                          checked={isSelected(code)}
                          onChange={(event) => {
                            if (selectedStatusCodes.length === 0) {
                              if (isSelected(code)) {
                                const newStatusCodes = [...defaultStatusCodes];

                                newStatusCodes.splice(
                                  newStatusCodes.indexOf(code),
                                  1
                                );

                                setValue(
                                  "checks.http.statusCodes",
                                  newStatusCodes
                                );
                              } else {
                                setValue("checks.http.statusCodes", [
                                  ...defaultStatusCodes,
                                  code,
                                ]);
                              }

                              return;
                            }

                            if (selectedStatusCodes.indexOf(code) === -1) {
                              setValue("checks.http.statusCodes", [
                                ...selectedStatusCodes,
                                code,
                              ]);
                            } else {
                              const newStatusCodes = [...selectedStatusCodes];

                              newStatusCodes.splice(
                                newStatusCodes.indexOf(code),
                                1
                              );

                              setValue(
                                "checks.http.statusCodes",
                                newStatusCodes
                              );
                            }
                          }}
                        />
                      }
                    />
                  ))}
                </Box>
              </Box>
            );
          })}

          <Button variant="contained" sx={{ mt: 5 }} onClick={onClose}>
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditHostCheckStatusCodes;
