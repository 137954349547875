import {
  Box,
  Breadcrumbs,
  Grid,
  Link as StyledLink,
  Typography,
} from "@mui/material";
import React, { ReactNode } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, To } from "react-router-dom";
import useLocale from "../hooks/useLocale";
import { Container } from "@mui/system";

interface Breadcrumb {
  name: ReactNode;
  to: To;
}

interface PageHeaderProps {
  breadcrumbs?: Breadcrumb[];
  actions?: ReactNode;
  title?: ReactNode;
}

const PageHeader = ({ breadcrumbs, actions, title }: PageHeaderProps) => {
  const { localizePath } = useLocale();

  const breadcrumbElements = (breadcrumbs || []).map((breadcrumb, index) => {
    return (
      <StyledLink
        underline="hover"
        key={index}
        color="inherit"
        to={localizePath(breadcrumb.to)}
        component={Link}
      >
        {breadcrumb.name}
      </StyledLink>
    );
  });

  return (
    <Container component={"header"}>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              paddingTop: 1,
              paddingBottom: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography component="h1" variant="h5" sx={{ lineHeight: 1 }}>
                {title}
              </Typography>

              <Breadcrumbs
                sx={{
                  marginBottom: 2,
                  fontSize: 15,
                  display: {
                    xs: "none",
                    sm: "flex",
                  },
                }}
                separator={<NavigateNextIcon fontSize="small" />}
              >
                {breadcrumbElements}
              </Breadcrumbs>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "100%",
              marginLeft: {
                xs: "0",
                sm: "auto",
              },
              width: {
                xs: "100%",
              },
              alignItems: "center",
            }}
          >
            {actions}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageHeader;
