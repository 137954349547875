import React from "react";
import IntegrationLayout from "../layouts/IntegrationLayout";
import { ReactComponent as SlackLogo } from "../../images/integrations/slack.svg";
import { Trans } from "@lingui/macro";

const SlackIntegrationResult = () => {
  return (
    <IntegrationLayout
      logo={<SlackLogo />}
      title={<Trans>Slack integration</Trans>}
      subHeader={<Trans>Send notifications via Slack</Trans>}
    />
  );
};

export default SlackIntegrationResult;
