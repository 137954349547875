import { NavigateOptions, To, useNavigate, useParams } from "react-router-dom";
import { locales } from "../components/utils/LanguagePicker";
import { localeVar } from "../utils/localeVar";

export default function useLocale() {
  const navigate = useNavigate();
  const { locale: localeFromUrl } = useParams();
  const currentLocale = locales[String(localeFromUrl)] ? localeFromUrl : null;

  if (!currentLocale) {
    localeVar("en");
  }

  const localizePath = (path: To, locale?: string): To => {
    return "/" + (locale || currentLocale || "en") + path;
  };

  return {
    locale: currentLocale,

    navigate: (to: To, locale?: string, options?: NavigateOptions) => {
      return navigate(
        localizePath(to, locale || currentLocale || "en"),
        options
      );
    },

    localizePath,
  };
}
