import { Trans } from "@lingui/macro";
import { Pause, PlayArrow } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import {
  HostByIdResponse,
  HostState,
  useChangeHostStateMutation,
} from "../../hooks.generated";

interface HostStateChangeProps {
  host: HostByIdResponse["host"];
}

const HostStateChange = ({ host }: HostStateChangeProps) => {
  const [changeHostState] = useChangeHostStateMutation();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <ToggleButtonGroup
      value={host?.state}
      exclusive
      disabled={loading}
      onChange={async (event, value) => {
        console.log(value);
        setLoading(true);

        try {
          await changeHostState({
            variables: {
              hostId: String(host?.id),
              state: value || host?.state,
            },
          });

          enqueueSnackbar(<Trans>Changed host state</Trans>);
        } catch (exception) {
          enqueueSnackbar(<Trans>Failed to change the host state</Trans>);
        }

        setLoading(false);
      }}
      size={"small"}
    >
      <ToggleButton value={HostState.Active} sx={{ padding: "2px" }}>
        <PlayArrow />
      </ToggleButton>
      <ToggleButton value={HostState.Inactive} sx={{ padding: "2px" }}>
        <Pause />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default HostStateChange;
